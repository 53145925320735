import { sizeMap, type ThemeSizingOption } from '../sizing.ts'

const getSizeClassName = (size?: ThemeSizingOption) => {
  if (!size) {
    return ''
  }

  return sizeMap[size]
}

export { getSizeClassName }
