import type { TupleToUnion } from 'type-fest'

const flexBasisNames = [
  '0',
  'px',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
  '24',
  '28',
  '32',
  '36',
  '40',
  '44',
  '48',
  '52',
  '56',
  '60',
  '64',
  '72',
  '80',
  '96',
  'auto',
  '1/2',
  '1/3',
  '2/3',
  '1/4',
  '2/4',
  '3/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '1/6',
  '2/6',
  '3/6',
  '4/6',
  '5/6',
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
  'full',
] as const

type ThemeFlexBasis = TupleToUnion<typeof flexBasisNames>

const flexBasisMap: Record<ThemeFlexBasis, string> = {
  '0': 'basis-0',
  px: 'basis-px',
  '0.5': 'basis-0.5',
  '1': 'basis-1',
  '1.5': 'basis-1.5',
  '2': 'basis-2',
  '2.5': 'basis-2.5',
  '3': 'basis-3',
  '3.5': 'basis-3.5',
  '4': 'basis-4',
  '5': 'basis-5',
  '6': 'basis-6',
  '7': 'basis-7',
  '8': 'basis-8',
  '9': 'basis-9',
  '10': 'basis-10',
  '11': 'basis-11',
  '12': 'basis-12',
  '14': 'basis-14',
  '16': 'basis-16',
  '20': 'basis-20',
  '24': 'basis-24',
  '28': 'basis-28',
  '32': 'basis-32',
  '36': 'basis-36',
  '40': 'basis-40',
  '44': 'basis-44',
  '48': 'basis-48',
  '52': 'basis-52',
  '56': 'basis-56',
  '60': 'basis-60',
  '64': 'basis-64',
  '72': 'basis-72',
  '80': 'basis-80',
  '96': 'basis-96',
  auto: 'basis-auto',
  '1/2': 'basis-1/2',
  '1/3': 'basis-1/3',
  '2/3': 'basis-2/3',
  '1/4': 'basis-1/4',
  '2/4': 'basis-2/4',
  '3/4': 'basis-3/4',
  '1/5': 'basis-1/5',
  '2/5': 'basis-2/5',
  '3/5': 'basis-3/5',
  '4/5': 'basis-4/5',
  '1/6': 'basis-1/6',
  '2/6': 'basis-2/6',
  '3/6': 'basis-3/6',
  '4/6': 'basis-4/6',
  '5/6': 'basis-5/6',
  '1/12': 'basis-1/12',
  '2/12': 'basis-2/12',
  '3/12': 'basis-3/12',
  '4/12': 'basis-4/12',
  '5/12': 'basis-5/12',
  '6/12': 'basis-6/12',
  '7/12': 'basis-7/12',
  '8/12': 'basis-8/12',
  '9/12': 'basis-9/12',
  '10/12': 'basis-10/12',
  '11/12': 'basis-11/12',
  full: 'basis-full',
}

const isFlexBasisOption = (value: string): value is ThemeFlexBasis =>
  flexBasisNames.includes(value as ThemeFlexBasis)

const getFlexBasisClassName = (value: string) => {
  if (isFlexBasisOption(value)) {
    return flexBasisMap[value]
  }

  return null
}

export {
  flexBasisMap,
  flexBasisNames,
  getFlexBasisClassName,
  isFlexBasisOption,
  type ThemeFlexBasis,
}
