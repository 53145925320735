import { cn } from '@mntn-dev/ui-utilities'

import {
  getFlexAlignContentClassName,
  getFlexAlignItemsClassName,
  getFlexAlignSelfClassName,
} from '../flex/align.ts'
import { getFlexBasisClassName } from '../flex/basis.ts'
import { getFlexDirectionClassName } from '../flex/direction.ts'
import {
  type FlexChildPropName,
  type FlexParentPropName,
  type ThemeFlexChild,
  type ThemeFlexParent,
  flexChildPropNames,
  flexParentPropNames,
} from '../flex/flex.ts'
import { getFlexGrowthClassName } from '../flex/growth.ts'
import {
  getFlexJustifyContentClassName,
  getFlexJustifyItemsClassName,
  getFlexJustifySelfClassName,
} from '../flex/justify.ts'
import { getFlexOrderClassName } from '../flex/order.ts'
import {
  getFlexPlaceContentClassName,
  getFlexPlaceItemsClassName,
  getFlexPlaceSelfClassName,
} from '../flex/place.ts'
import { getFlexWrapClassName } from '../flex/wrap.ts'
import { getDivideClassName } from './divide.ts'
import { getGapClassName } from './gap.ts'

const flexParentFuncMap: Partial<
  Record<FlexParentPropName, (value: string) => string | null>
> = {
  alignContent: getFlexAlignContentClassName,
  alignItems: getFlexAlignItemsClassName,
  direction: getFlexDirectionClassName,
  justifyContent: getFlexJustifyContentClassName,
  justifyItems: getFlexJustifyItemsClassName,
  placeContent: getFlexPlaceContentClassName,
  placeItems: getFlexPlaceItemsClassName,
  wrap: getFlexWrapClassName,
}

const flexChildFuncMap: Partial<
  Record<FlexChildPropName, (value: string) => string | null>
> = {
  alignSelf: getFlexAlignSelfClassName,
  basis: getFlexBasisClassName,
  growth: getFlexGrowthClassName,
  justifySelf: getFlexJustifySelfClassName,
  order: getFlexOrderClassName,
  placeSelf: getFlexPlaceSelfClassName,
}

const getFlexParentClassName = (flexParentObj?: ThemeFlexParent) => {
  if (!flexParentObj) {
    return undefined
  }

  const flexParentClassName = flexParentPropNames.reduce<string>(
    (className, flexParentPropName) => {
      const flexValue = flexParentObj[flexParentPropName]

      if (!flexValue) {
        return className
      }

      const flexFunc = flexParentFuncMap[flexParentPropName]

      if (flexFunc && typeof flexValue === 'string') {
        return `${className} ${flexFunc(flexValue)}`
      }

      return className
    },
    ''
  )

  const orientation =
    flexParentObj.direction === 'col' ? 'vertical' : 'horizontal'
  const divideClassName = getDivideClassName(
    orientation,
    flexParentObj.divide,
    flexParentObj
  )
  const gapClassName = getGapClassName(flexParentObj)
  const { inline } = flexParentObj

  return cn(flexParentClassName, gapClassName, divideClassName, {
    flex: !inline,
    'inline-flex': inline,
  })
}

const getFlexChildClassName = (flexChildObj?: ThemeFlexChild) => {
  if (!flexChildObj) {
    return undefined
  }

  const flexChildClassName = flexChildPropNames.reduce<string>(
    (className, flexChildPropName) => {
      const flexValue = flexChildObj[flexChildPropName]

      if (!flexValue) {
        return className
      }

      const flexFunc = flexChildFuncMap[flexChildPropName]

      if (flexFunc && typeof flexValue === 'string') {
        return `${className} ${flexFunc(flexValue)}`
      }

      return className
    },
    ''
  )

  const { grow, shrink } = flexChildObj

  return cn(flexChildClassName, {
    grow: grow,
    'grow-0': grow !== undefined && !grow,
    shrink: shrink,
    'shrink-0': shrink !== undefined && !shrink,
  })
}

export { getFlexParentClassName, getFlexChildClassName, getGapClassName }
