import { getMarginMap, marginNames, type ThemeMargin } from '../margin.ts'

const getMarginClassName = (marginObj?: ThemeMargin) => {
  if (!marginObj) {
    return null
  }

  const marginClassName = marginNames.reduce<string>(
    (className, marginName) => {
      const marginValue = marginObj[marginName]

      if (marginValue !== undefined) {
        const mapper = getMarginMap(marginName)
        return `${className} ${mapper[marginValue]}`
      }

      return className
    },
    ''
  )

  return marginClassName === '' ? null : marginClassName
}

export { getMarginClassName }
