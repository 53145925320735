import type { Positioning } from './positioning.ts'

export const positioningTopMap: Record<Positioning, string> = {
  '0': 'top-0',
  px: 'top-px',
  '0.5': 'top-0.5',
  '1': 'top-1',
  '1.5': 'top-1.5',
  '2': 'top-2',
  '2.5': 'top-2.5',
  '3': 'top-3',
  '3.5': 'top-3.5',
  '4': 'top-4',
  '5': 'top-5',
  '6': 'top-6',
  '7': 'top-7',
  '8': 'top-8',
  '9': 'top-9',
  '10': 'top-10',
  '11': 'top-11',
  '12': 'top-12',
  '14': 'top-14',
  '16': 'top-16',
  '20': 'top-20',
  '24': 'top-24',
  '28': 'top-28',
  '32': 'top-32',
  '36': 'top-36',
  '40': 'top-40',
  '44': 'top-44',
  '48': 'top-48',
  '52': 'top-52',
  '56': 'top-56',
  '60': 'top-60',
  '64': 'top-64',
  '72': 'top-72',
  '80': 'top-80',
  '96': 'top-96',
  auto: 'top-auto',
  '1/2': 'top-1/2',
  '1/3': 'top-1/3',
  '2/3': 'top-2/3',
  '2/4': 'top-2/4',
  '3/4': 'top-3/4',
  full: 'top-full',
  '-px': '-top-px',
  '-0.5': '-top-0.5',
  '-1': '-top-1',
  '-1.5': '-top-1.5',
  '-2': '-top-2',
  '-2.5': '-top-2.5',
  '-3': '-top-3',
  '-3.5': '-top-3.5',
  '-4': '-top-4',
  '-5': '-top-5',
  '-6': '-top-6',
  '-7': '-top-7',
  '-8': '-top-8',
  '-9': '-top-9',
  '-10': '-top-10',
  '-11': '-top-11',
  '-12': '-top-12',
  '-14': '-top-14',
  '-16': '-top-16',
  '-20': '-top-20',
  '-24': '-top-24',
  '-28': '-top-28',
  '-32': '-top-32',
  '-36': '-top-36',
  '-40': '-top-40',
  '-44': '-top-44',
  '-48': '-top-48',
  '-52': '-top-52',
  '-56': '-top-56',
  '-60': '-top-60',
  '-64': '-top-64',
  '-72': '-top-72',
  '-80': '-top-80',
  '-96': '-top-96',
  '-1/2': '-top-1/2',
  '-1/3': '-top-1/3',
  '-2/3': '-top-2/3',
  '-2/4': '-top-2/4',
  '-3/4': '-top-3/4',
}
