import { isMaxWidthOption, isMinWidthOption, isWidthOption } from '../sizing.ts'
import {
  maxWidthMap,
  minWidthMap,
  widthMap,
  widthNames,
  type ThemeWidth,
  type Width,
} from '../width.ts'

const getBaseWidthClassName = (widthValue: string) => {
  if (isWidthOption(widthValue)) {
    return widthMap[widthValue]
  }

  return null
}

const getMaxWidthClassName = (maxWidthValue: string) => {
  if (isMaxWidthOption(maxWidthValue)) {
    return maxWidthMap[maxWidthValue]
  }

  return null
}

const getMinWidthClassName = (minWidthValue: string) => {
  if (isMinWidthOption(minWidthValue)) {
    return minWidthMap[minWidthValue]
  }

  return null
}

const widthFuncMap: Record<Width, (widthValue: string) => string | null> = {
  width: getBaseWidthClassName,
  maxWidth: getMaxWidthClassName,
  minWidth: getMinWidthClassName,
}

const getWidthClassName = (width?: ThemeWidth) => {
  if (!width) {
    return null
  }

  return widthNames
    .reduce<string>((className, widthKey) => {
      const widthValue = width[widthKey]

      if (!widthValue) {
        return className
      }
      const widthFunc = widthFuncMap[widthKey]
      const widthClassName = widthFunc(widthValue)

      return widthClassName ? `${className} ${widthClassName}` : className
    }, '')
    .trim()
}

export { getWidthClassName }
