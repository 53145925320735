import {
  LanguageIds as defaultLanguageIds,
  type LanguageId,
} from '@mntn-dev/domain-types'

/**
 * Checks if the given language identifier is a valid language identifier.
 * @param id The language identifier to check.
 * @param languageIds An array of valid language identifiers to check against.
 * @returns A boolean indicating whether the given language identifier is valid or not.
 */
export function isLanguageId<L extends string = LanguageId>(
  id?: unknown,
  languageIds?: L[]
): id is L {
  return (languageIds ?? defaultLanguageIds).some((lid) => lid === id)
}
