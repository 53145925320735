import { cn } from '@mntn-dev/ui-utilities'
import { type ThemeDivideColor, themeDivideColorMap } from '../border-color.ts'
import {
  type ThemeGap,
  columnGapDivideSpaceMap,
  rowGapDivideSpaceMap,
} from '../gap.ts'

type Orientation = 'horizontal' | 'vertical'

const divideOrientationMap: Record<Orientation, string> = {
  horizontal: 'divide-x',
  vertical: 'divide-y',
}

const getDivideGapSpacing = (orientation: Orientation, gapObj?: ThemeGap) => {
  if (!gapObj) {
    return null
  }

  const { columnGap, rowGap, gap } = gapObj

  const gapValue = (orientation === 'horizontal' ? columnGap : rowGap) ?? gap
  const map =
    orientation === 'horizontal'
      ? columnGapDivideSpaceMap
      : rowGapDivideSpaceMap

  return gapValue ? map[gapValue] : null
}

export const getDivideClassName = (
  orientation: Orientation = 'horizontal',
  divideColor?: ThemeDivideColor,
  gap?: ThemeGap
) => {
  if (!divideColor) {
    return null
  }

  return cn(
    divideOrientationMap[orientation],
    themeDivideColorMap[divideColor],
    getDivideGapSpacing(orientation, gap)
  )
}
