import type { TupleToUnion } from 'type-fest'

const gapNames = [
  '0',
  'px',
  '0.5',
  '1',
  '1.5',
  '2',
  '2.5',
  '3',
  '3.5',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '14',
  '16',
  '20',
  '24',
  '28',
  '32',
  '36',
  '40',
  '44',
  '48',
  '52',
  '56',
  '60',
  '64',
  '72',
  '80',
  '96',
] as const
type ThemeGapValue = TupleToUnion<typeof gapNames>

const gapMap: Record<ThemeGapValue, string> = {
  '0': 'gap-0',
  px: 'gap-px',
  '0.5': 'gap-0.5',
  '1': 'gap-1',
  '1.5': 'gap-1.5',
  '2': 'gap-2',
  '2.5': 'gap-2.5',
  '3': 'gap-3',
  '3.5': 'gap-3.5',
  '4': 'gap-4',
  '5': 'gap-5',
  '6': 'gap-6',
  '7': 'gap-7',
  '8': 'gap-8',
  '9': 'gap-9',
  '10': 'gap-10',
  '11': 'gap-11',
  '12': 'gap-12',
  '14': 'gap-14',
  '16': 'gap-16',
  '20': 'gap-20',
  '24': 'gap-24',
  '28': 'gap-28',
  '32': 'gap-32',
  '36': 'gap-36',
  '40': 'gap-40',
  '44': 'gap-44',
  '48': 'gap-48',
  '52': 'gap-52',
  '56': 'gap-56',
  '60': 'gap-60',
  '64': 'gap-64',
  '72': 'gap-72',
  '80': 'gap-80',
  '96': 'gap-96',
}

const columnGapMap: Record<ThemeGapValue, string> = {
  '0': 'gap-x-0',
  px: 'gap-x-px',
  '0.5': 'gap-x-0.5',
  '1': 'gap-x-1',
  '1.5': 'gap-x-1.5',
  '2': 'gap-x-2',
  '2.5': 'gap-x-2.5',
  '3': 'gap-x-3',
  '3.5': 'gap-x-3.5',
  '4': 'gap-x-4',
  '5': 'gap-x-5',
  '6': 'gap-x-6',
  '7': 'gap-x-7',
  '8': 'gap-x-8',
  '9': 'gap-x-9',
  '10': 'gap-x-10',
  '11': 'gap-x-11',
  '12': 'gap-x-12',
  '14': 'gap-x-14',
  '16': 'gap-x-16',
  '20': 'gap-x-20',
  '24': 'gap-x-24',
  '28': 'gap-x-28',
  '32': 'gap-x-32',
  '36': 'gap-x-36',
  '40': 'gap-x-40',
  '44': 'gap-x-44',
  '48': 'gap-x-48',
  '52': 'gap-x-52',
  '56': 'gap-x-56',
  '60': 'gap-x-60',
  '64': 'gap-x-64',
  '72': 'gap-x-72',
  '80': 'gap-x-80',
  '96': 'gap-x-96',
}

const rowGapMap: Record<ThemeGapValue, string> = {
  '0': 'gap-y-0',
  px: 'gap-y-px',
  '0.5': 'gap-y-0.5',
  '1': 'gap-y-1',
  '1.5': 'gap-y-1.5',
  '2': 'gap-y-2',
  '2.5': 'gap-y-2.5',
  '3': 'gap-y-3',
  '3.5': 'gap-y-3.5',
  '4': 'gap-y-4',
  '5': 'gap-y-5',
  '6': 'gap-y-6',
  '7': 'gap-y-7',
  '8': 'gap-y-8',
  '9': 'gap-y-9',
  '10': 'gap-y-10',
  '11': 'gap-y-11',
  '12': 'gap-y-12',
  '14': 'gap-y-14',
  '16': 'gap-y-16',
  '20': 'gap-y-20',
  '24': 'gap-y-24',
  '28': 'gap-y-28',
  '32': 'gap-y-32',
  '36': 'gap-y-36',
  '40': 'gap-y-40',
  '44': 'gap-y-44',
  '48': 'gap-y-48',
  '52': 'gap-y-52',
  '56': 'gap-y-56',
  '60': 'gap-y-60',
  '64': 'gap-y-64',
  '72': 'gap-y-72',
  '80': 'gap-y-80',
  '96': 'gap-y-96',
}

const columnGapDivideSpaceMap: Record<ThemeGapValue, string> = {
  '0': '', // no spacer needed, leave blank
  px: '[&>*:not(:first-child)]:pl-px',
  '0.5': '[&>*:not(:first-child)]:pl-0.5',
  '1': '[&>*:not(:first-child)]:pl-1',
  '1.5': '[&>*:not(:first-child)]:pl-1.5',
  '2': '[&>*:not(:first-child)]:pl-2',
  '2.5': '[&>*:not(:first-child)]:pl-2.5',
  '3': '[&>*:not(:first-child)]:pl-3',
  '3.5': '[&>*:not(:first-child)]:pl-3.5',
  '4': '[&>*:not(:first-child)]:pl-4',
  '5': '[&>*:not(:first-child)]:pl-5',
  '6': '[&>*:not(:first-child)]:pl-6',
  '7': '[&>*:not(:first-child)]:pl-7',
  '8': '[&>*:not(:first-child)]:pl-8',
  '9': '[&>*:not(:first-child)]:pl-9',
  '10': '[&>*:not(:first-child)]:pl-10',
  '11': '[&>*:not(:first-child)]:pl-11',
  '12': '[&>*:not(:first-child)]:pl-12',
  '14': '[&>*:not(:first-child)]:pl-14',
  '16': '[&>*:not(:first-child)]:pl-16',
  '20': '[&>*:not(:first-child)]:pl-20',
  '24': '[&>*:not(:first-child)]:pl-24',
  '28': '[&>*:not(:first-child)]:pl-28',
  '32': '[&>*:not(:first-child)]:pl-32',
  '36': '[&>*:not(:first-child)]:pl-36',
  '40': '[&>*:not(:first-child)]:pl-40',
  '44': '[&>*:not(:first-child)]:pl-44',
  '48': '[&>*:not(:first-child)]:pl-48',
  '52': '[&>*:not(:first-child)]:pl-52',
  '56': '[&>*:not(:first-child)]:pl-56',
  '60': '[&>*:not(:first-child)]:pl-60',
  '64': '[&>*:not(:first-child)]:pl-64',
  '72': '[&>*:not(:first-child)]:pl-72',
  '80': '[&>*:not(:first-child)]:pl-80',
  '96': '[&>*:not(:first-child)]:pl-96',
}

const rowGapDivideSpaceMap: Record<ThemeGapValue, string> = {
  '0': '', // no spacer needed, leave blank
  px: '[&>*:not(:first-child):pt-px',
  '0.5': '[&>*:not(:first-child)]:pt-0.5',
  '1': '[&>*:not(:first-child)]:pt-1',
  '1.5': '[&>*:not(:first-child)]:pt-1.5',
  '2': '[&>*:not(:first-child)]:pt-2',
  '2.5': '[&>*:not(:first-child)]:pt-2.5',
  '3': '[&>*:not(:first-child)]:pt-3',
  '3.5': '[&>*:not(:first-child)]:pt-3.5',
  '4': '[&>*:not(:first-child)]:pt-4',
  '5': '[&>*:not(:first-child)]:pt-5',
  '6': '[&>*:not(:first-child)]:pt-6',
  '7': '[&>*:not(:first-child)]:pt-7',
  '8': '[&>*:not(:first-child)]:pt-8',
  '9': '[&>*:not(:first-child)]:pt-9',
  '10': '[&>*:not(:first-child)]:pt-10',
  '11': '[&>*:not(:first-child)]:pt-11',
  '12': '[&>*:not(:first-child)]:pt-12',
  '14': '[&>*:not(:first-child)]:pt-14',
  '16': '[&>*:not(:first-child)]:pt-16',
  '20': '[&>*:not(:first-child)]:pt-20',
  '24': '[&>*:not(:first-child)]:pt-24',
  '28': '[&>*:not(:first-child)]:pt-28',
  '32': '[&>*:not(:first-child)]:pt-32',
  '36': '[&>*:not(:first-child)]:pt-36',
  '40': '[&>*:not(:first-child)]:pt-40',
  '44': '[&>*:not(:first-child)]:pt-44',
  '48': '[&>*:not(:first-child)]:pt-48',
  '52': '[&>*:not(:first-child)]:pt-52',
  '56': '[&>*:not(:first-child)]:pt-56',
  '60': '[&>*:not(:first-child)]:pt-60',
  '64': '[&>*:not(:first-child)]:pt-64',
  '72': '[&>*:not(:first-child)]:pt-72',
  '80': '[&>*:not(:first-child)]:pt-80',
  '96': '[&>*:not(:first-child)]:pt-96',
}

const gapPropNames = ['gap', 'columnGap', 'rowGap'] as const
type GapPropName = TupleToUnion<typeof gapPropNames>

type ThemeGap = Partial<Record<GapPropName, ThemeGapValue>>

const gapMapMap: Record<GapPropName, Record<ThemeGapValue, string>> = {
  gap: gapMap,
  columnGap: columnGapMap,
  rowGap: rowGapMap,
}

const getGapMap = (propName: GapPropName) => gapMapMap[propName]

export {
  columnGapMap,
  columnGapDivideSpaceMap,
  gapMap,
  gapMapMap,
  gapNames,
  gapPropNames,
  getGapMap,
  rowGapMap,
  rowGapDivideSpaceMap,
  type GapPropName,
  type ThemeGap,
  type ThemeGapValue,
}
