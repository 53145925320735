import type { NextRequest } from 'next/server'

import {
  LanguageIds as defaultLanguageIds,
  type LanguageId,
} from '@mntn-dev/domain-types'

/**
 * Determines whether the request URL contains a language ID segment.
 * @param {NextRequest} request - The `NextRequest` object representing the incoming HTTP request.
 * @param {LanguageId[]} [languageIds=defaultLanguageIds] - An array of language IDs to check for in the request URL. Defaults to the `languageIds` setting.
 * @returns {boolean} `true` if the request URL contains a language ID segment and is not a Next.js internal URL. Otherwise, `false`.
 */
export function languageInRequestPath<L extends string = LanguageId>(
  request: NextRequest,
  languageIds?: L[]
) {
  return (
    (languageIds ?? defaultLanguageIds).some((lid) =>
      request.nextUrl.pathname.startsWith(`/${lid}`)
    ) &&
    !request.nextUrl.pathname.startsWith('/_next') &&
    !request.nextUrl.pathname.startsWith('/api')
  )
}
