import type { TupleToUnion } from 'type-fest'

const flexOrderNames = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'first',
  'last',
  'none',
] as const

type ThemeFlexOrder = TupleToUnion<typeof flexOrderNames>

const flexOrderMap: Record<ThemeFlexOrder, string> = {
  '1': 'order-1',
  '2': 'order-2',
  '3': 'order-3',
  '4': 'order-4',
  '5': 'order-5',
  '6': 'order-6',
  '7': 'order-7',
  '8': 'order-8',
  '9': 'order-9',
  '10': 'order-10',
  '11': 'order-11',
  '12': 'order-12',
  first: 'order-first',
  last: 'order-last',
  none: 'order-none',
}

const isFlexOrderOption = (value: string): value is ThemeFlexOrder =>
  flexOrderNames.includes(value as ThemeFlexOrder)

const getFlexOrderClassName = (value: string) => {
  if (isFlexOrderOption(value)) {
    return flexOrderMap[value]
  }

  return null
}

export {
  flexOrderMap,
  getFlexOrderClassName,
  isFlexOrderOption,
  type ThemeFlexOrder,
}
