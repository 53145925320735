import type { Positioning } from './positioning.ts'

export const positioningRightMap: Record<Positioning, string> = {
  '0': 'right-0',
  px: 'right-px',
  '0.5': 'right-0.5',
  '1': 'right-1',
  '1.5': 'right-1.5',
  '2': 'right-2',
  '2.5': 'right-2.5',
  '3': 'right-3',
  '3.5': 'right-3.5',
  '4': 'right-4',
  '5': 'right-5',
  '6': 'right-6',
  '7': 'right-7',
  '8': 'right-8',
  '9': 'right-9',
  '10': 'right-10',
  '11': 'right-11',
  '12': 'right-12',
  '14': 'right-14',
  '16': 'right-16',
  '20': 'right-20',
  '24': 'right-24',
  '28': 'right-28',
  '32': 'right-32',
  '36': 'right-36',
  '40': 'right-40',
  '44': 'right-44',
  '48': 'right-48',
  '52': 'right-52',
  '56': 'right-56',
  '60': 'right-60',
  '64': 'right-64',
  '72': 'right-72',
  '80': 'right-80',
  '96': 'right-96',
  auto: 'right-auto',
  '1/2': 'right-1/2',
  '1/3': 'right-1/3',
  '2/3': 'right-2/3',
  '2/4': 'right-2/4',
  '3/4': 'right-3/4',
  full: 'right-full',
  '-px': '-right-px',
  '-0.5': '-right-0.5',
  '-1': '-right-1',
  '-1.5': '-right-1.5',
  '-2': '-right-2',
  '-2.5': '-right-2.5',
  '-3': '-right-3',
  '-3.5': '-right-3.5',
  '-4': '-right-4',
  '-5': '-right-5',
  '-6': '-right-6',
  '-7': '-right-7',
  '-8': '-right-8',
  '-9': '-right-9',
  '-10': '-right-10',
  '-11': '-right-11',
  '-12': '-right-12',
  '-14': '-right-14',
  '-16': '-right-16',
  '-20': '-right-20',
  '-24': '-right-24',
  '-28': '-right-28',
  '-32': '-right-32',
  '-36': '-right-36',
  '-40': '-right-40',
  '-44': '-right-44',
  '-48': '-right-48',
  '-52': '-right-52',
  '-56': '-right-56',
  '-60': '-right-60',
  '-64': '-right-64',
  '-72': '-right-72',
  '-80': '-right-80',
  '-96': '-right-96',
  '-1/2': '-right-1/2',
  '-1/3': '-right-1/3',
  '-2/3': '-right-2/3',
  '-2/4': '-right-2/4',
  '-3/4': '-right-3/4',
}
