import type { TupleToUnion } from 'type-fest'

const flexPlaceContentNames = [
  'center',
  'start',
  'end',
  'between',
  'around',
  'evenly',
  'baseline',
  'stretch',
] as const
type ThemeFlexPlaceContent = TupleToUnion<typeof flexPlaceContentNames>

const flexPlaceItemsNames = [
  'start',
  'end',
  'center',
  'baseline',
  'stretch',
] as const
type ThemeFlexPlaceItems = TupleToUnion<typeof flexPlaceItemsNames>

const flexPlaceSelfNames = [
  'auto',
  'start',
  'end',
  'center',
  'stretch',
] as const
type ThemeFlexPlaceSelf = TupleToUnion<typeof flexPlaceSelfNames>

const flexPlaceContentMap: Record<ThemeFlexPlaceContent, string> = {
  center: 'place-content-center',
  start: 'place-content-start',
  end: 'place-content-end',
  between: 'place-content-between',
  around: 'place-content-around',
  evenly: 'place-content-evenly',
  baseline: 'place-content-baseline',
  stretch: 'place-content-stretch',
}

const isFlexPlaceContentOption = (
  value: string
): value is ThemeFlexPlaceContent =>
  flexPlaceContentNames.includes(value as ThemeFlexPlaceContent)

const getFlexPlaceContentClassName = (value: string) => {
  if (isFlexPlaceContentOption(value)) {
    return flexPlaceContentMap[value]
  }

  return null
}

const flexPlaceItemsMap: Record<ThemeFlexPlaceItems, string> = {
  start: 'place-items-start',
  end: 'place-items-end',
  center: 'place-items-center',
  baseline: 'place-items-baseline',
  stretch: 'place-items-stretch',
}

const isFlexPlaceItemsOption = (value: string): value is ThemeFlexPlaceItems =>
  flexPlaceItemsNames.includes(value as ThemeFlexPlaceItems)

const getFlexPlaceItemsClassName = (value: string) => {
  if (isFlexPlaceItemsOption(value)) {
    return flexPlaceItemsMap[value]
  }

  return null
}

const flexPlaceSelfMap: Record<ThemeFlexPlaceSelf, string> = {
  auto: 'place-self-auto',
  start: 'place-self-start',
  end: 'place-self-end',
  center: 'place-self-center',
  stretch: 'place-self-stretch',
}

const isFlexPlaceSelfOption = (value: string): value is ThemeFlexPlaceSelf =>
  flexPlaceSelfNames.includes(value as ThemeFlexPlaceSelf)

const getFlexPlaceSelfClassName = (value: string) => {
  if (isFlexPlaceSelfOption(value)) {
    return flexPlaceSelfMap[value]
  }

  return null
}

export {
  getFlexPlaceContentClassName,
  getFlexPlaceItemsClassName,
  getFlexPlaceSelfClassName,
  flexPlaceContentMap,
  flexPlaceItemsMap,
  flexPlaceSelfMap,
  isFlexPlaceContentOption,
  isFlexPlaceItemsOption,
  isFlexPlaceSelfOption,
  type ThemeFlexPlaceContent,
  type ThemeFlexPlaceItems,
  type ThemeFlexPlaceSelf,
}
