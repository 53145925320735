import type { TupleToUnion } from 'type-fest'

const flexWrapNames = ['wrap', 'wrap-reverse', 'nowrap'] as const
type ThemeFlexWrap = TupleToUnion<typeof flexWrapNames>

const flexWrapMap: Record<ThemeFlexWrap, string> = {
  wrap: 'flex-wrap',
  'wrap-reverse': 'flex-wrap-reverse',
  nowrap: 'flex-nowrap',
}

const isFlexWrapOption = (value: string): value is ThemeFlexWrap =>
  flexWrapNames.includes(value as ThemeFlexWrap)

const getFlexWrapClassName = (value: string) => {
  if (isFlexWrapOption(value)) {
    return flexWrapMap[value]
  }

  return null
}

export {
  flexWrapMap,
  getFlexWrapClassName,
  isFlexWrapOption,
  type ThemeFlexWrap,
}
