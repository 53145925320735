import type { NextRequest } from 'next/server'

import {
  LanguageIds as defaultLanguageIds,
  type LanguageId,
} from '@mntn-dev/domain-types'

/**
 * Determines the user's preferred language based on the referer URL.
 * @param {NextRequest} request - The `NextRequest` object representing the incoming HTTP request.
 * @param {LanguageId[]} [languageIds=defaultLanguageIds] - An array of language IDs to check for in the referer URL. Defaults to the `languageIds` setting.
 * @returns {LanguageId | undefined} A `LanguageId` string representing the user's preferred language if it can be detected from the referer URL. Otherwise, `undefined` is returned.
 */
export function getLanguageFromReferer<L extends string = LanguageId>(
  request: NextRequest,
  languageIds?: L[]
) {
  if (!request.headers.has('referer')) {
    return undefined
  }

  const refererHeaderValue = request.headers.get('referer')
  const refererUrl = refererHeaderValue && new URL(refererHeaderValue)
  const lngInReferer =
    (refererUrl &&
      (languageIds ?? defaultLanguageIds).find((l) =>
        refererUrl.pathname.startsWith(`/${l}`)
      )) ||
    undefined

  return lngInReferer
}
