import { cn } from '@mntn-dev/ui-utilities'

import {
  getFlexAlignContentClassName,
  getFlexAlignItemsClassName,
  getFlexAlignSelfClassName,
} from '../flex/align.ts'
import {
  getFlexJustifyContentClassName,
  getFlexJustifyItemsClassName,
  getFlexJustifySelfClassName,
} from '../flex/justify.ts'
import {
  getFlexPlaceContentClassName,
  getFlexPlaceItemsClassName,
  getFlexPlaceSelfClassName,
} from '../flex/place.ts'
import {
  getGridAutoColumnsClassName,
  getGridColumnEndClassName,
  getGridColumnSpanClassName,
  getGridColumnStartClassName,
  getGridColumnsClassName,
} from '../grid/columns.ts'
import { getGridAutoFlowClassName } from '../grid/flow.ts'
import {
  type GridChildPropName,
  type GridParentPropName,
  type ThemeGridChild,
  type ThemeGridParent,
  gridChildPropNames,
  gridParentPropNames,
} from '../grid/grid.ts'
import {
  getGridAutoRowsClassName,
  getGridRowEndClassName,
  getGridRowSpanClassName,
  getGridRowStartClassName,
  getGridRowsClassName,
} from '../grid/rows.ts'
import { getGapClassName } from './gap.ts'

// biome-ignore lint/suspicious/noExplicitAny: value could be multiple things depending on prop
type GridClassGetterFunc = (value: any) => string | null

const gridParentFuncMap: Partial<
  Record<GridParentPropName, GridClassGetterFunc>
> = {
  alignContent: getFlexAlignContentClassName,
  alignItems: getFlexAlignItemsClassName,
  autoColumns: getGridAutoColumnsClassName,
  autoFlow: getGridAutoFlowClassName,
  autoRows: getGridAutoRowsClassName,
  columns: getGridColumnsClassName,
  justifyContent: getFlexJustifyContentClassName,
  justifyItems: getFlexJustifyItemsClassName,
  placeContent: getFlexPlaceContentClassName,
  placeItems: getFlexPlaceItemsClassName,
  rows: getGridRowsClassName,
}

const gridChildFuncMap: Partial<
  Record<GridChildPropName, GridClassGetterFunc>
> = {
  alignSelf: getFlexAlignSelfClassName,
  columnSpan: getGridColumnSpanClassName,
  columnStart: getGridColumnStartClassName,
  columnEnd: getGridColumnEndClassName,
  justifySelf: getFlexJustifySelfClassName,
  placeSelf: getFlexPlaceSelfClassName,
  rowSpan: getGridRowSpanClassName,
  rowStart: getGridRowStartClassName,
  rowEnd: getGridRowEndClassName,
}

const getGridParentClassName = (gridParentObj?: ThemeGridParent) => {
  if (!gridParentObj) {
    return undefined
  }

  const gridParentClassName = gridParentPropNames
    .reduce<string>((className, gridParentPropName) => {
      const gridValue = gridParentObj[gridParentPropName]

      if (!gridValue) {
        return className
      }

      const gridFunc = gridParentFuncMap[gridParentPropName]

      if (
        gridFunc &&
        (typeof gridValue === 'string' || typeof gridValue === 'number')
      ) {
        return `${className} ${gridFunc(gridValue)}`
      }

      return className
    }, '')
    .trim()

  const gapClassName = getGapClassName(gridParentObj)
  const { inline } = gridParentObj

  return cn(gridParentClassName, gapClassName, {
    grid: !inline,
    'inline-grid': inline,
  })
}

const getGridChildClassName = (gridChildObj?: ThemeGridChild) => {
  if (!gridChildObj) {
    return undefined
  }

  const gridChildClassName = gridChildPropNames
    .reduce<string>((className, gridChildPropName) => {
      const gridValue = gridChildObj[gridChildPropName]

      if (!gridValue) {
        return className
      }

      const gridFunc = gridChildFuncMap[gridChildPropName]

      if (
        gridFunc &&
        (typeof gridValue === 'string' || typeof gridValue === 'number')
      ) {
        return `${className} ${gridFunc(gridValue)}`
      }

      return className
    }, '')
    .trim()

  return cn(gridChildClassName)
}

export { getGridParentClassName, getGridChildClassName }
