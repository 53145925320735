import type { TupleToUnion } from 'type-fest'

import { themeTextColorNames } from './text-color.ts'

const iconColorNames = [...themeTextColorNames, 'close'] as const

type ThemeIconColor = TupleToUnion<typeof iconColorNames>

const themeIconColorMap: Record<ThemeIconColor, string> = {
  primary: 'fill-white/90',
  'primary-inverse': 'fill-black/90',
  secondary: 'fill-white/60',
  'secondary-inverse': 'fill-black/60',
  tertiary: 'fill-white/40',
  disabled: 'fill-white/20',
  'disabled-inverse': 'fill-black/20',
  link: 'fill-primary-blue',
  'link-hover': 'fill-blue-100',
  'link-pressed': 'fill-blue-500',
  'link-subtle': 'fill-primary-blue/30',
  info: 'fill-primary-blue',
  positive: 'fill-primary-green',
  caution: 'fill-primary-yellow',
  negative: 'fill-primary-red',
  notice: 'fill-primary-purple',
  close: 'fill-blue-600/50',
  current: 'fill-current',
}

const themeIconStrokeColorMap: Record<ThemeIconColor, string> = {
  primary: 'stroke-white/90',
  'primary-inverse': 'stroke-black/90',
  secondary: 'stroke-white/60',
  'secondary-inverse': 'stroke-black/60',
  tertiary: 'stroke-white/40',
  disabled: 'stroke-white/20',
  'disabled-inverse': 'stroke-black/20',
  link: 'stroke-primary-blue',
  'link-hover': 'stroke-blue-100',
  'link-pressed': 'stroke-blue-500',
  'link-subtle': 'stroke-primary-blue/30',
  info: 'stroke-primary-blue',
  positive: 'stroke-primary-green',
  caution: 'stroke-primary-yellow',
  negative: 'stroke-primary-red',
  notice: 'stroke-primary-purple',
  close: 'stroke-blue-600/50',
  current: 'stroke-current',
}

export {
  iconColorNames,
  type ThemeIconColor,
  themeIconColorMap,
  themeIconStrokeColorMap,
}
