import { getPaddingMap, paddingNames, type ThemePadding } from '../padding.ts'

const getPaddingClassName = (paddingObj?: ThemePadding) => {
  if (!paddingObj) {
    return null
  }

  const paddingClassName = paddingNames
    .reduce<string>((className, paddingName) => {
      const paddingValue = paddingObj[paddingName]

      if (paddingValue !== undefined) {
        const mapper = getPaddingMap(paddingName)
        return `${className} ${mapper[paddingValue]}`
      }

      return className
    }, '')
    .trim()

  return paddingClassName === '' ? null : paddingClassName
}

export { getPaddingClassName }
