import type React from 'react'

import type { AbstractLinkProps } from './types.ts'

export const NativeLink: React.FC<AbstractLinkProps> = ({
  children,
  ...props
}) => {
  return <a {...props}>{children}</a>
}
