import { gapPropNames, getGapMap, type ThemeGap } from '../gap.ts'

const getGapClassName = (gapObj?: ThemeGap) => {
  if (!gapObj) {
    return null
  }

  const gapClassName = gapPropNames
    .reduce<string>((className, gapPropName) => {
      const gapValue = gapObj[gapPropName]

      if (gapValue !== undefined) {
        const mapper = getGapMap(gapPropName)
        return `${className} ${mapper[gapValue]}`
      }

      return className
    }, '')
    .trim()

  return gapClassName === '' ? null : gapClassName
}

export { getGapClassName }
