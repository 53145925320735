import type { TupleToUnion } from 'type-fest'

import type { ThemeDivideColor } from '../border-color.ts'
import type { ThemeGap } from '../gap.ts'
import type {
  ThemeFlexAlignContent,
  ThemeFlexAlignItems,
  ThemeFlexAlignSelf,
} from './align.ts'
import type { ThemeFlexBasis } from './basis.ts'
import type { ThemeFlexDirection } from './direction.ts'
import type { ThemeFlexGrowth } from './growth.ts'
import type {
  ThemeFlexJustifyContent,
  ThemeFlexJustifyItems,
  ThemeFlexJustifySelf,
} from './justify.ts'
import type { ThemeFlexOrder } from './order.ts'
import type {
  ThemeFlexPlaceContent,
  ThemeFlexPlaceItems,
  ThemeFlexPlaceSelf,
} from './place.ts'
import type { ThemeFlexWrap } from './wrap.ts'

const flexParentPropNames = [
  'alignContent',
  'alignItems',
  'columnGap',
  'direction',
  'divide',
  'gap',
  'inline',
  'justifyContent',
  'justifyItems',
  'placeContent',
  'placeItems',
  'rowGap',
  'wrap',
] as const
type FlexParentPropName = TupleToUnion<typeof flexParentPropNames>

const flexChildPropNames = [
  'alignSelf',
  'basis',
  'grow',
  'growth',
  'justifySelf',
  'order',
  'placeSelf',
  'shrink',
] as const
type FlexChildPropName = TupleToUnion<typeof flexChildPropNames>

type ThemeFlexParent = ThemeGap & {
  alignContent?: ThemeFlexAlignContent
  alignItems?: ThemeFlexAlignItems
  direction?: ThemeFlexDirection
  divide?: ThemeDivideColor
  inline?: boolean
  justifyContent?: ThemeFlexJustifyContent
  justifyItems?: ThemeFlexJustifyItems
  placeContent?: ThemeFlexPlaceContent
  placeItems?: ThemeFlexPlaceItems
  wrap?: ThemeFlexWrap
}

type ThemeFlexChild = {
  alignSelf?: ThemeFlexAlignSelf
  basis?: ThemeFlexBasis
  grow?: boolean
  growth?: ThemeFlexGrowth
  justifySelf?: ThemeFlexJustifySelf
  order?: ThemeFlexOrder
  placeSelf?: ThemeFlexPlaceSelf
  shrink?: boolean
}

export {
  flexChildPropNames,
  flexParentPropNames,
  type FlexChildPropName,
  type FlexParentPropName,
  type ThemeFlexChild,
  type ThemeFlexParent,
}
