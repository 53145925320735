import {
  heightMap,
  heightNames,
  maxHeightMap,
  minHeightMap,
  type Height,
  type ThemeHeight,
} from '../height.ts'
import {
  isHeightOption,
  isMaxHeightOption,
  isMinHeightOption,
} from '../sizing.ts'

const getBaseHeightClassName = (heightValue: string) => {
  if (isHeightOption(heightValue)) {
    return heightMap[heightValue]
  }

  return null
}

const getMaxHeightClassName = (maxHeightValue: string) => {
  if (isMaxHeightOption(maxHeightValue)) {
    return maxHeightMap[maxHeightValue]
  }

  return null
}

const getMinHeightClassName = (minHeightValue: string) => {
  if (isMinHeightOption(minHeightValue)) {
    return minHeightMap[minHeightValue]
  }

  return null
}

const heightFuncMap: Record<Height, (heightValue: string) => string | null> = {
  height: getBaseHeightClassName,
  maxHeight: getMaxHeightClassName,
  minHeight: getMinHeightClassName,
}

const getHeightClassName = (height?: ThemeHeight) => {
  if (!height) {
    return null
  }

  return heightNames
    .reduce<string>((className, heightKey) => {
      const heightValue = height[heightKey]

      if (!heightValue) {
        return className
      }
      const heightFunc = heightFuncMap[heightKey]
      const heightClassName = heightFunc(heightValue)

      return heightClassName ? `${className} ${heightClassName}` : className
    }, '')
    .trim()
}

export { getHeightClassName }
