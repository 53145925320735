import type { TupleToUnion } from 'type-fest'

const elevationNames = ['xs', 'sm', 'md', 'lg', 'xl'] as const
type ThemeElevation = TupleToUnion<typeof elevationNames>

const elevationMap: Record<ThemeElevation, string> = {
  xs: 'shadow-elevation-xs',
  sm: 'shadow-elevation-sm',
  md: 'shadow-elevation-md',
  lg: 'shadow-elevation-lg',
  xl: 'shadow-elevation-xl',
}

export { elevationMap, elevationNames, type ThemeElevation }
