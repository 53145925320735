import type { TupleToUnion } from 'type-fest'

import type { ThemeSpacing } from './spacing.ts'

const marginMap: Record<ThemeSpacing, string> = {
  '0': 'm-0',
  px: 'm-px',
  '0.5': 'm-0.5',
  '1': 'm-1',
  '1.5': 'm-1.5',
  '2': 'm-2',
  '2.5': 'm-2.5',
  '3': 'm-3',
  '3.5': 'm-3.5',
  '4': 'm-4',
  '5': 'm-5',
  '6': 'm-6',
  '7': 'm-7',
  '8': 'm-8',
  '9': 'm-9',
  '10': 'm-10',
  '11': 'm-11',
  '12': 'm-12',
  '14': 'm-14',
  '16': 'm-16',
  '20': 'm-20',
  '24': 'm-24',
  '28': 'm-28',
  '32': 'm-32',
  '36': 'm-36',
  '40': 'm-40',
  '44': 'm-44',
  '48': 'm-48',
  '52': 'm-52',
  '56': 'm-56',
  '60': 'm-60',
  '64': 'm-64',
  '72': 'm-72',
  '80': 'm-80',
  '96': 'm-96',
  '112': 'm-112',
  '128': 'm-128',
  '144': 'm-144',
  '160': 'm-160',
  '176': 'm-176',
  '192': 'm-192',
}

const marginXMap: Record<ThemeSpacing, string> = {
  '0': 'mx-0',
  px: 'mx-px',
  '0.5': 'mx-0.5',
  '1': 'mx-1',
  '1.5': 'mx-1.5',
  '2': 'mx-2',
  '2.5': 'mx-2.5',
  '3': 'mx-3',
  '3.5': 'mx-3.5',
  '4': 'mx-4',
  '5': 'mx-5',
  '6': 'mx-6',
  '7': 'mx-7',
  '8': 'mx-8',
  '9': 'mx-9',
  '10': 'mx-10',
  '11': 'mx-11',
  '12': 'mx-12',
  '14': 'mx-14',
  '16': 'mx-16',
  '20': 'mx-20',
  '24': 'mx-24',
  '28': 'mx-28',
  '32': 'mx-32',
  '36': 'mx-36',
  '40': 'mx-40',
  '44': 'mx-44',
  '48': 'mx-48',
  '52': 'mx-52',
  '56': 'mx-56',
  '60': 'mx-60',
  '64': 'mx-64',
  '72': 'mx-72',
  '80': 'mx-80',
  '96': 'mx-96',
  '112': 'mx-112',
  '128': 'mx-128',
  '144': 'mx-144',
  '160': 'mx-160',
  '176': 'mx-176',
  '192': 'mx-192',
}

const marginYMap: Record<ThemeSpacing, string> = {
  '0': 'my-0',
  px: 'my-px',
  '0.5': 'my-0.5',
  '1': 'my-1',
  '1.5': 'my-1.5',
  '2': 'my-2',
  '2.5': 'my-2.5',
  '3': 'my-3',
  '3.5': 'my-3.5',
  '4': 'my-4',
  '5': 'my-5',
  '6': 'my-6',
  '7': 'my-7',
  '8': 'my-8',
  '9': 'my-9',
  '10': 'my-10',
  '11': 'my-11',
  '12': 'my-12',
  '14': 'my-14',
  '16': 'my-16',
  '20': 'my-20',
  '24': 'my-24',
  '28': 'my-28',
  '32': 'my-32',
  '36': 'my-36',
  '40': 'my-40',
  '44': 'my-44',
  '48': 'my-48',
  '52': 'my-52',
  '56': 'my-56',
  '60': 'my-60',
  '64': 'my-64',
  '72': 'my-72',
  '80': 'my-80',
  '96': 'my-96',
  '112': 'my-112',
  '128': 'my-128',
  '144': 'my-144',
  '160': 'my-160',
  '176': 'my-176',
  '192': 'my-192',
}

const marginTopMap: Record<ThemeSpacing, string> = {
  '0': 'mt-0',
  px: 'mt-px',
  '0.5': 'mt-0.5',
  '1': 'mt-1',
  '1.5': 'mt-1.5',
  '2': 'mt-2',
  '2.5': 'mt-2.5',
  '3': 'mt-3',
  '3.5': 'mt-3.5',
  '4': 'mt-4',
  '5': 'mt-5',
  '6': 'mt-6',
  '7': 'mt-7',
  '8': 'mt-8',
  '9': 'mt-9',
  '10': 'mt-10',
  '11': 'mt-11',
  '12': 'mt-12',
  '14': 'mt-14',
  '16': 'mt-16',
  '20': 'mt-20',
  '24': 'mt-24',
  '28': 'mt-28',
  '32': 'mt-32',
  '36': 'mt-36',
  '40': 'mt-40',
  '44': 'mt-44',
  '48': 'mt-48',
  '52': 'mt-52',
  '56': 'mt-56',
  '60': 'mt-60',
  '64': 'mt-64',
  '72': 'mt-72',
  '80': 'mt-80',
  '96': 'mt-96',
  '112': 'mt-112',
  '128': 'mt-128',
  '144': 'mt-144',
  '160': 'mt-160',
  '176': 'mt-176',
  '192': 'mt-192',
}

const marginRightMap: Record<ThemeSpacing, string> = {
  '0': 'mr-0',
  px: 'mr-px',
  '0.5': 'mr-0.5',
  '1': 'mr-1',
  '1.5': 'mr-1.5',
  '2': 'mr-2',
  '2.5': 'mr-2.5',
  '3': 'mr-3',
  '3.5': 'mr-3.5',
  '4': 'mr-4',
  '5': 'mr-5',
  '6': 'mr-6',
  '7': 'mr-7',
  '8': 'mr-8',
  '9': 'mr-9',
  '10': 'mr-10',
  '11': 'mr-11',
  '12': 'mr-12',
  '14': 'mr-14',
  '16': 'mr-16',
  '20': 'mr-20',
  '24': 'mr-24',
  '28': 'mr-28',
  '32': 'mr-32',
  '36': 'mr-36',
  '40': 'mr-40',
  '44': 'mr-44',
  '48': 'mr-48',
  '52': 'mr-52',
  '56': 'mr-56',
  '60': 'mr-60',
  '64': 'mr-64',
  '72': 'mr-72',
  '80': 'mr-80',
  '96': 'mr-96',
  '112': 'mr-112',
  '128': 'mr-128',
  '144': 'mr-144',
  '160': 'mr-160',
  '176': 'mr-176',
  '192': 'mr-192',
}

const marginBottomMap: Record<ThemeSpacing, string> = {
  '0': 'mb-0',
  px: 'mb-px',
  '0.5': 'mb-0.5',
  '1': 'mb-1',
  '1.5': 'mb-1.5',
  '2': 'mb-2',
  '2.5': 'mb-2.5',
  '3': 'mb-3',
  '3.5': 'mb-3.5',
  '4': 'mb-4',
  '5': 'mb-5',
  '6': 'mb-6',
  '7': 'mb-7',
  '8': 'mb-8',
  '9': 'mb-9',
  '10': 'mb-10',
  '11': 'mb-11',
  '12': 'mb-12',
  '14': 'mb-14',
  '16': 'mb-16',
  '20': 'mb-20',
  '24': 'mb-24',
  '28': 'mb-28',
  '32': 'mb-32',
  '36': 'mb-36',
  '40': 'mb-40',
  '44': 'mb-44',
  '48': 'mb-48',
  '52': 'mb-52',
  '56': 'mb-56',
  '60': 'mb-60',
  '64': 'mb-64',
  '72': 'mb-72',
  '80': 'mb-80',
  '96': 'mb-96',
  '112': 'mb-112',
  '128': 'mb-128',
  '144': 'mb-144',
  '160': 'mb-160',
  '176': 'mb-176',
  '192': 'mb-192',
}

const marginLeftMap: Record<ThemeSpacing, string> = {
  '0': 'ml-0',
  px: 'ml-px',
  '0.5': 'ml-0.5',
  '1': 'ml-1',
  '1.5': 'ml-1.5',
  '2': 'ml-2',
  '2.5': 'ml-2.5',
  '3': 'ml-3',
  '3.5': 'ml-3.5',
  '4': 'ml-4',
  '5': 'ml-5',
  '6': 'ml-6',
  '7': 'ml-7',
  '8': 'ml-8',
  '9': 'ml-9',
  '10': 'ml-10',
  '11': 'ml-11',
  '12': 'ml-12',
  '14': 'ml-14',
  '16': 'ml-16',
  '20': 'ml-20',
  '24': 'ml-24',
  '28': 'ml-28',
  '32': 'ml-32',
  '36': 'ml-36',
  '40': 'ml-40',
  '44': 'ml-44',
  '48': 'ml-48',
  '52': 'ml-52',
  '56': 'ml-56',
  '60': 'ml-60',
  '64': 'ml-64',
  '72': 'ml-72',
  '80': 'ml-80',
  '96': 'ml-96',
  '112': 'ml-112',
  '128': 'ml-128',
  '144': 'ml-144',
  '160': 'ml-160',
  '176': 'ml-176',
  '192': 'ml-192',
}

const marginNames = [
  'margin',
  'marginX',
  'marginY',
  'marginTop',
  'marginRight',
  'marginBottom',
  'marginLeft',
] as const
type MarginName = TupleToUnion<typeof marginNames>

type ThemeMargin = Partial<Record<MarginName, ThemeSpacing>>

const marginMapMap: Record<MarginName, Record<ThemeSpacing, string>> = {
  margin: marginMap,
  marginX: marginXMap,
  marginY: marginYMap,
  marginTop: marginTopMap,
  marginRight: marginRightMap,
  marginBottom: marginBottomMap,
  marginLeft: marginLeftMap,
}

const getMarginMap = (propName: MarginName) => marginMapMap[propName]

export {
  getMarginMap,
  marginBottomMap,
  marginLeftMap,
  marginMap,
  marginNames,
  marginRightMap,
  marginTopMap,
  marginXMap,
  marginYMap,
  type ThemeMargin,
}
