import type { TupleToUnion } from 'type-fest'

const flexAlignContentNames = [
  'normal',
  'center',
  'start',
  'end',
  'between',
  'around',
  'evenly',
  'baseline',
  'stretch',
] as const
type ThemeFlexAlignContent = TupleToUnion<typeof flexAlignContentNames>

const flexAlignItemsNames = [
  'start',
  'end',
  'center',
  'baseline',
  'stretch',
] as const
type ThemeFlexAlignItems = TupleToUnion<typeof flexAlignItemsNames>

const flexAlignSelfNames = [
  'auto',
  'start',
  'end',
  'center',
  'baseline',
  'stretch',
] as const
type ThemeFlexAlignSelf = TupleToUnion<typeof flexAlignSelfNames>

const flexAlignContentMap: Record<ThemeFlexAlignContent, string> = {
  normal: 'content-normal',
  center: 'content-center',
  start: 'content-start',
  end: 'content-end',
  between: 'content-between',
  around: 'content-around',
  evenly: 'content-evenly',
  baseline: 'content-baseline',
  stretch: 'content-stretch',
}

const isFlexAlignContentOption = (
  value: string
): value is ThemeFlexAlignContent =>
  flexAlignContentNames.includes(value as ThemeFlexAlignContent)

const getFlexAlignContentClassName = (value: string) => {
  if (isFlexAlignContentOption(value)) {
    return flexAlignContentMap[value]
  }

  return null
}

const flexAlignItemsMap: Record<ThemeFlexAlignItems, string> = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
}

const isFlexAlignItemsOption = (value: string): value is ThemeFlexAlignItems =>
  flexAlignItemsNames.includes(value as ThemeFlexAlignItems)

const getFlexAlignItemsClassName = (value: string) => {
  if (isFlexAlignItemsOption(value)) {
    return flexAlignItemsMap[value]
  }

  return null
}

const flexAlignSelfMap: Record<ThemeFlexAlignSelf, string> = {
  auto: 'self-auto',
  start: 'self-start',
  end: 'self-end',
  center: 'self-center',
  baseline: 'self-baseline',
  stretch: 'self-stretch',
}

const isFlexAlignSelfOption = (value: string): value is ThemeFlexAlignSelf =>
  flexAlignSelfNames.includes(value as ThemeFlexAlignSelf)

const getFlexAlignSelfClassName = (value: string) => {
  if (isFlexAlignSelfOption(value)) {
    return flexAlignSelfMap[value]
  }

  return null
}

export {
  getFlexAlignContentClassName,
  getFlexAlignItemsClassName,
  getFlexAlignSelfClassName,
  flexAlignContentMap,
  flexAlignItemsMap,
  flexAlignSelfMap,
  isFlexAlignContentOption,
  isFlexAlignItemsOption,
  isFlexAlignSelfOption,
  type ThemeFlexAlignContent,
  type ThemeFlexAlignItems,
  type ThemeFlexAlignSelf,
}
