import type { TupleToUnion } from 'type-fest'

const flexDirectionNames = ['row', 'row-reverse', 'col', 'col-reverse'] as const
type ThemeFlexDirection = TupleToUnion<typeof flexDirectionNames>

const flexDirectionMap: Record<ThemeFlexDirection, string> = {
  row: 'flex-row',
  'row-reverse': 'flex-row-reverse',
  col: 'flex-col',
  'col-reverse': 'flex-col-reverse',
}

const isFlexDirectionOption = (value: string): value is ThemeFlexDirection =>
  flexDirectionNames.includes(value as ThemeFlexDirection)

const getFlexDirectionClassName = (value: string) => {
  if (isFlexDirectionOption(value)) {
    return flexDirectionMap[value]
  }

  return null
}

export {
  flexDirectionMap,
  getFlexDirectionClassName,
  isFlexDirectionOption,
  type ThemeFlexDirection,
}
