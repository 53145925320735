import type { TupleToUnion } from 'type-fest'
import { type ThemeGap, gapPropNames } from '../gap.ts'
import type {
  ThemeGridAlignContent,
  ThemeGridAlignItems,
  ThemeGridAlignSelf,
} from './align.ts'
import type {
  ThemeGridAutoColumns,
  ThemeGridColumnEnd,
  ThemeGridColumnSpan,
  ThemeGridColumnStart,
  ThemeGridColumns,
} from './columns.ts'
import type { ThemeGridAutoFlow } from './flow.ts'
import type {
  ThemeGridJustifyContent,
  ThemeGridJustifyItems,
  ThemeGridJustifySelf,
} from './justify.ts'
import type {
  ThemeGridPlaceContent,
  ThemeGridPlaceItems,
  ThemeGridPlaceSelf,
} from './place.ts'
import type {
  ThemeGridAutoRows,
  ThemeGridRowEnd,
  ThemeGridRowSpan,
  ThemeGridRowStart,
  ThemeGridRows,
} from './rows.ts'

// TODO: extract this from ThemeGridParent dynamically if possible
const gridParentPropNames = [
  'alignContent',
  'alignItems',
  'autoColumns',
  'autoFlow',
  'autoRows',
  'columns',
  'inline',
  'justifyContent',
  'justifyItems',
  'placeContent',
  'placeItems',
  'rows',
  ...gapPropNames,
] as const

type GridParentPropName = TupleToUnion<typeof gridParentPropNames>

// TODO: extract this from ThemeGridChild dynamically if possible
const gridChildPropNames = [
  'alignSelf',
  'columnSpan',
  'columnStart',
  'columnEnd',
  'justifySelf',
  'placeSelf',
  'rowSpan',
  'rowStart',
  'rowEnd',
] as const

type GridChildPropName = TupleToUnion<typeof gridChildPropNames>

type ThemeGridParent = ThemeGap & {
  alignContent?: ThemeGridAlignContent
  alignItems?: ThemeGridAlignItems
  autoColumns?: ThemeGridAutoColumns
  autoFlow?: ThemeGridAutoFlow
  autoRows?: ThemeGridAutoRows
  columns?: ThemeGridColumns
  inline?: boolean
  justifyContent?: ThemeGridJustifyContent
  justifyItems?: ThemeGridJustifyItems
  placeContent?: ThemeGridPlaceContent
  placeItems?: ThemeGridPlaceItems
  rows?: ThemeGridRows
}

type ThemeGridChild = {
  alignSelf?: ThemeGridAlignSelf
  columnSpan?: ThemeGridColumnSpan
  columnStart?: ThemeGridColumnStart
  columnEnd?: ThemeGridColumnEnd
  justifySelf?: ThemeGridJustifySelf
  placeSelf?: ThemeGridPlaceSelf
  rowSpan?: ThemeGridRowSpan
  rowStart?: ThemeGridRowStart
  rowEnd?: ThemeGridRowEnd
}

export {
  gridChildPropNames,
  gridParentPropNames,
  type GridChildPropName,
  type GridParentPropName,
  type ThemeGridChild,
  type ThemeGridParent,
}
