import { createInstance, type FlatNamespace } from 'i18next'
import { initReactI18next } from 'react-i18next/initReactI18next'

import { LanguageIds, type LanguageId } from '@mntn-dev/domain-types'

import { getOptions } from '../get-options.ts'
import { defaultNS, fallbackLanguageId } from '../settings.ts'
import { initResourcesToBackEnd } from './init-resources-to-backend.ts'

export const initI18next = async (
  lng: LanguageId,
  ns: FlatNamespace = defaultNS
) => {
  const i18nInstance = createInstance()
  await i18nInstance
    .use(initReactI18next)
    .use(initResourcesToBackEnd())
    .init(getOptions(lng, ns, [...LanguageIds], defaultNS, fallbackLanguageId))
  return i18nInstance
}
