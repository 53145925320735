import type { Positioning } from './positioning.ts'

export const positioningLeftMap: Record<Positioning, string> = {
  '0': 'left-0',
  px: 'left-px',
  '0.5': 'left-0.5',
  '1': 'left-1',
  '1.5': 'left-1.5',
  '2': 'left-2',
  '2.5': 'left-2.5',
  '3': 'left-3',
  '3.5': 'left-3.5',
  '4': 'left-4',
  '5': 'left-5',
  '6': 'left-6',
  '7': 'left-7',
  '8': 'left-8',
  '9': 'left-9',
  '10': 'left-10',
  '11': 'left-11',
  '12': 'left-12',
  '14': 'left-14',
  '16': 'left-16',
  '20': 'left-20',
  '24': 'left-24',
  '28': 'left-28',
  '32': 'left-32',
  '36': 'left-36',
  '40': 'left-40',
  '44': 'left-44',
  '48': 'left-48',
  '52': 'left-52',
  '56': 'left-56',
  '60': 'left-60',
  '64': 'left-64',
  '72': 'left-72',
  '80': 'left-80',
  '96': 'left-96',
  auto: 'left-auto',
  '1/2': 'left-1/2',
  '1/3': 'left-1/3',
  '2/3': 'left-2/3',
  '2/4': 'left-2/4',
  '3/4': 'left-3/4',
  full: 'left-full',
  '-px': '-left-px',
  '-0.5': '-left-0.5',
  '-1': '-left-1',
  '-1.5': '-left-1.5',
  '-2': '-left-2',
  '-2.5': '-left-2.5',
  '-3': '-left-3',
  '-3.5': '-left-3.5',
  '-4': '-left-4',
  '-5': '-left-5',
  '-6': '-left-6',
  '-7': '-left-7',
  '-8': '-left-8',
  '-9': '-left-9',
  '-10': '-left-10',
  '-11': '-left-11',
  '-12': '-left-12',
  '-14': '-left-14',
  '-16': '-left-16',
  '-20': '-left-20',
  '-24': '-left-24',
  '-28': '-left-28',
  '-32': '-left-32',
  '-36': '-left-36',
  '-40': '-left-40',
  '-44': '-left-44',
  '-48': '-left-48',
  '-52': '-left-52',
  '-56': '-left-56',
  '-60': '-left-60',
  '-64': '-left-64',
  '-72': '-left-72',
  '-80': '-left-80',
  '-96': '-left-96',
  '-1/2': '-left-1/2',
  '-1/3': '-left-1/3',
  '-2/3': '-left-2/3',
  '-2/4': '-left-2/4',
  '-3/4': '-left-3/4',
}
