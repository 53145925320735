import type { TupleToUnion } from 'type-fest'

import { themeTextColorNames } from './text-color.ts'

const markerColorNames = [...themeTextColorNames] as const

type ThemeMarkerColor = TupleToUnion<typeof themeTextColorNames>

const themeMarkerColorMap: Record<ThemeMarkerColor, string> = {
  primary: 'marker:text-white/90',
  'primary-inverse': 'marker:text-black/90',
  secondary: 'marker:text-white/60',
  'secondary-inverse': 'marker:text-black/60',
  tertiary: 'marker:text-white/40',
  disabled: 'marker:text-white/20',
  'disabled-inverse': 'marker:text-black/20',
  link: 'marker:text-primary-blue',
  'link-hover': 'marker:text-blue-100',
  'link-pressed': 'marker:text-blue-500',
  'link-subtle': 'marker:text-primary-blue/30',
  info: 'marker:text-primary-blue',
  positive: 'marker:text-primary-green',
  caution: 'marker:text-primary-yellow',
  negative: 'marker:text-primary-red',
  notice: 'marker:text-primary-purple',
  current: 'marker:text-current',
}

export { markerColorNames, type ThemeMarkerColor, themeMarkerColorMap }
