import type { TupleToUnion } from 'type-fest'

const gridRowValues = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'none',
  'subgrid',
] as const
type ThemeGridRows = TupleToUnion<typeof gridRowValues>

const gridRowValuesMap: Record<ThemeGridRows, string> = {
  1: 'grid-rows-1',
  2: 'grid-rows-2',
  3: 'grid-rows-3',
  4: 'grid-rows-4',
  5: 'grid-rows-5',
  6: 'grid-rows-6',
  7: 'grid-rows-7',
  8: 'grid-rows-8',
  9: 'grid-rows-9',
  10: 'grid-rows-10',
  11: 'grid-rows-11',
  12: 'grid-rows-12',
  none: 'grid-rows-none',
  subgrid: 'grid-rows-subgrid',
}

const isGridRowsOption = (value: string): value is ThemeGridRows =>
  gridRowValues.includes(value as ThemeGridRows)

const getGridRowsClassName = (value: string) => {
  if (isGridRowsOption(value)) {
    return gridRowValuesMap[value]
  }

  return null
}

const gridAutoRowsNames = ['auto', 'min', 'max', 'fr'] as const
type ThemeGridAutoRows = TupleToUnion<typeof gridAutoRowsNames>

const gridAutoRowsMap: Record<ThemeGridAutoRows, string> = {
  auto: 'auto-rows-auto',
  min: 'auto-rows-min',
  max: 'auto-rows-max',
  fr: 'auto-rows-fr',
}

const isGridAutoRowsOption = (value: string): value is ThemeGridAutoRows =>
  gridAutoRowsNames.includes(value as ThemeGridAutoRows)

const getGridAutoRowsClassName = (value: string) => {
  if (isGridAutoRowsOption(value)) {
    return gridAutoRowsMap[value]
  }

  return null
}

const gridRowSpan = [
  'auto',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  'full',
] as const

type ThemeGridRowSpan = TupleToUnion<typeof gridRowSpan>

const gridRowSpanMap: Record<ThemeGridRowSpan, string> = {
  auto: 'row-span-auto',
  1: 'row-span-1',
  2: 'row-span-2',
  3: 'row-span-3',
  4: 'row-span-4',
  5: 'row-span-5',
  6: 'row-span-6',
  7: 'row-span-7',
  8: 'row-span-8',
  9: 'row-span-9',
  10: 'row-span-10',
  11: 'row-span-11',
  12: 'row-span-12',
  full: 'row-span-full',
}

const isGridRowSpanOption = (value: string): value is ThemeGridRowSpan =>
  gridRowSpan.includes(value as ThemeGridRowSpan)

const getGridRowSpanClassName = (value: string) => {
  if (isGridRowSpanOption(value)) {
    return gridRowSpanMap[value]
  }

  return null
}

const gridRowStart = [
  'auto',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
] as const

type ThemeGridRowStart = TupleToUnion<typeof gridRowStart>

const gridRowStartMap: Record<ThemeGridRowStart, string> = {
  auto: 'row-start-auto',
  1: 'row-start-1',
  2: 'row-start-2',
  3: 'row-start-3',
  4: 'row-start-4',
  5: 'row-start-5',
  6: 'row-start-6',
  7: 'row-start-7',
  8: 'row-start-8',
  9: 'row-start-9',
  10: 'row-start-10',
  11: 'row-start-11',
  12: 'row-start-12',
  13: 'row-start-13',
}

const isGridRowStartOption = (value: string): value is ThemeGridRowStart =>
  gridRowStart.includes(value as ThemeGridRowStart)

const getGridRowStartClassName = (value: string) => {
  if (isGridRowStartOption(value)) {
    return gridRowStartMap[value]
  }

  return null
}

const gridRowEnd = [
  'auto',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
] as const

type ThemeGridRowEnd = TupleToUnion<typeof gridRowEnd>

const gridRowEndMap: Record<ThemeGridRowEnd, string> = {
  auto: 'row-end-auto',
  1: 'row-end-1',
  2: 'row-end-2',
  3: 'row-end-3',
  4: 'row-end-4',
  5: 'row-end-5',
  6: 'row-end-6',
  7: 'row-end-7',
  8: 'row-end-8',
  9: 'row-end-9',
  10: 'row-end-10',
  11: 'row-end-11',
  12: 'row-end-12',
  13: 'row-end-13',
}

const isGridRowEndOption = (value: string): value is ThemeGridRowEnd =>
  gridRowEnd.includes(value as ThemeGridRowEnd)

const getGridRowEndClassName = (value: string) => {
  if (isGridRowEndOption(value)) {
    return gridRowEndMap[value]
  }

  return null
}

export {
  getGridAutoRowsClassName,
  getGridRowEndClassName,
  getGridRowsClassName,
  getGridRowSpanClassName,
  getGridRowStartClassName,
  gridAutoRowsNames,
  gridRowEnd,
  gridRowSpan,
  gridRowStart,
  gridRowValues,
  type ThemeGridAutoRows,
  type ThemeGridRowEnd,
  type ThemeGridRows,
  type ThemeGridRowSpan,
  type ThemeGridRowStart,
}
