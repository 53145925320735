import type { TupleToUnion } from 'type-fest'

const flexGrowthNames = [
  'shrink-basis',
  'grow-free',
  'grow-basis',
  'grow-none',
] as const

type ThemeFlexGrowth = TupleToUnion<typeof flexGrowthNames>

const flexGrowthMap: Record<ThemeFlexGrowth, string> = {
  'shrink-basis': 'flex-initial',
  'grow-free': 'flex-auto',
  'grow-basis': 'flex-1',
  'grow-none': 'flex-none',
}

const isFlexGrowthOption = (value: string): value is ThemeFlexGrowth =>
  flexGrowthNames.includes(value as ThemeFlexGrowth)
const getFlexGrowthClassName = (value: string) => {
  if (isFlexGrowthOption(value)) {
    return flexGrowthMap[value]
  }

  return null
}

export {
  flexGrowthMap,
  getFlexGrowthClassName,
  isFlexGrowthOption,
  type ThemeFlexGrowth,
}
