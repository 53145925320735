import type { TupleToUnion } from 'type-fest'

const lineClamps = [1, 2, 3, 4, 5, 6, 7, 8, 9] as const

type LineClamp = TupleToUnion<typeof lineClamps>

const lineClampMap: Record<LineClamp, string> = {
  1: 'line-clamp-1',
  2: 'line-clamp-2',
  3: 'line-clamp-3',
  4: 'line-clamp-4',
  5: 'line-clamp-5',
  6: 'line-clamp-6',
  7: 'line-clamp-7',
  8: 'line-clamp-8',
  9: 'line-clamp-9',
}

export { lineClamps, type LineClamp, lineClampMap }
