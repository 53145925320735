import type { NextRequest } from 'next/server'
import acceptLanguage from 'accept-language'

import type { LanguageId } from '@mntn-dev/domain-types'

import { cookieName as defaultCookieName } from '../settings.ts'
import { isLanguageId } from './is-language-id.ts'

/**
 * Determines the user's preferred language based on the `Accept-Language` header and/or a language cookie.
 * @param {NextRequest} request - The `NextRequest` object representing the incoming HTTP request.
 * @param {string} [cookieName=defaultCookieName] - The name of the cookie to use for language detection. Defaults to `'next-i18n-locale'`.
 * @param {LanguageId} [fallbackLanguageId='en'] - The language ID to use if no language can be detected from the request. Defaults to `'en'`.
 * @returns {LanguageId} A `LanguageId` string representing the user's preferred language. If no language can be detected from the request, the `fallbackLanguageId` is returned.
 */
export function getLanguage(
  request: NextRequest,
  cookieName: string = defaultCookieName,
  fallbackLanguageId: LanguageId = 'en'
) {
  const cookieValue =
    request.cookies.has(cookieName) &&
    acceptLanguage.get(request.cookies.get(cookieName)?.value)

  const acceptHeaderValue =
    !cookieValue &&
    request.headers.has('Accept-Language') &&
    acceptLanguage.get(request.headers.get('Accept-Language'))

  const lng = cookieValue || acceptHeaderValue

  return isLanguageId(lng) ? lng : fallbackLanguageId
}
