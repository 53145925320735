import type { Positioning } from './positioning.ts'

export const positioningBottomMap: Record<Positioning, string> = {
  '0': 'bottom-0',
  px: 'bottom-px',
  '0.5': 'bottom-0.5',
  '1': 'bottom-1',
  '1.5': 'bottom-1.5',
  '2': 'bottom-2',
  '2.5': 'bottom-2.5',
  '3': 'bottom-3',
  '3.5': 'bottom-3.5',
  '4': 'bottom-4',
  '5': 'bottom-5',
  '6': 'bottom-6',
  '7': 'bottom-7',
  '8': 'bottom-8',
  '9': 'bottom-9',
  '10': 'bottom-10',
  '11': 'bottom-11',
  '12': 'bottom-12',
  '14': 'bottom-14',
  '16': 'bottom-16',
  '20': 'bottom-20',
  '24': 'bottom-24',
  '28': 'bottom-28',
  '32': 'bottom-32',
  '36': 'bottom-36',
  '40': 'bottom-40',
  '44': 'bottom-44',
  '48': 'bottom-48',
  '52': 'bottom-52',
  '56': 'bottom-56',
  '60': 'bottom-60',
  '64': 'bottom-64',
  '72': 'bottom-72',
  '80': 'bottom-80',
  '96': 'bottom-96',
  auto: 'bottom-auto',
  '1/2': 'bottom-1/2',
  '1/3': 'bottom-1/3',
  '2/3': 'bottom-2/3',
  '2/4': 'bottom-2/4',
  '3/4': 'bottom-3/4',
  full: 'bottom-full',
  '-px': '-bottom-px',
  '-0.5': '-bottom-0.5',
  '-1': '-bottom-1',
  '-1.5': '-bottom-1.5',
  '-2': '-bottom-2',
  '-2.5': '-bottom-2.5',
  '-3': '-bottom-3',
  '-3.5': '-bottom-3.5',
  '-4': '-bottom-4',
  '-5': '-bottom-5',
  '-6': '-bottom-6',
  '-7': '-bottom-7',
  '-8': '-bottom-8',
  '-9': '-bottom-9',
  '-10': '-bottom-10',
  '-11': '-bottom-11',
  '-12': '-bottom-12',
  '-14': '-bottom-14',
  '-16': '-bottom-16',
  '-20': '-bottom-20',
  '-24': '-bottom-24',
  '-28': '-bottom-28',
  '-32': '-bottom-32',
  '-36': '-bottom-36',
  '-40': '-bottom-40',
  '-44': '-bottom-44',
  '-48': '-bottom-48',
  '-52': '-bottom-52',
  '-56': '-bottom-56',
  '-60': '-bottom-60',
  '-64': '-bottom-64',
  '-72': '-bottom-72',
  '-80': '-bottom-80',
  '-96': '-bottom-96',
  '-1/2': '-bottom-1/2',
  '-1/3': '-bottom-1/3',
  '-2/3': '-bottom-2/3',
  '-2/4': '-bottom-2/4',
  '-3/4': '-bottom-3/4',
}
