import type { Positioning } from './positioning.ts'

export const positioningInsetMap: Record<Positioning, string> = {
  '0': 'inset-0',
  px: 'inset-px',
  '0.5': 'inset-0.5',
  '1': 'inset-1',
  '1.5': 'inset-1.5',
  '2': 'inset-2',
  '2.5': 'inset-2.5',
  '3': 'inset-3',
  '3.5': 'inset-3.5',
  '4': 'inset-4',
  '5': 'inset-5',
  '6': 'inset-6',
  '7': 'inset-7',
  '8': 'inset-8',
  '9': 'inset-9',
  '10': 'inset-10',
  '11': 'inset-11',
  '12': 'inset-12',
  '14': 'inset-14',
  '16': 'inset-16',
  '20': 'inset-20',
  '24': 'inset-24',
  '28': 'inset-28',
  '32': 'inset-32',
  '36': 'inset-36',
  '40': 'inset-40',
  '44': 'inset-44',
  '48': 'inset-48',
  '52': 'inset-52',
  '56': 'inset-56',
  '60': 'inset-60',
  '64': 'inset-64',
  '72': 'inset-72',
  '80': 'inset-80',
  '96': 'inset-96',
  auto: 'inset-auto',
  '1/2': 'inset-1/2',
  '1/3': 'inset-1/3',
  '2/3': 'inset-2/3',
  '2/4': 'inset-2/4',
  '3/4': 'inset-3/4',
  full: 'inset-full',
  '-px': '-inset-px',
  '-0.5': '-inset-0.5',
  '-1': '-inset-1',
  '-1.5': '-inset-1.5',
  '-2': '-inset-2',
  '-2.5': '-inset-2.5',
  '-3': '-inset-3',
  '-3.5': '-inset-3.5',
  '-4': '-inset-4',
  '-5': '-inset-5',
  '-6': '-inset-6',
  '-7': '-inset-7',
  '-8': '-inset-8',
  '-9': '-inset-9',
  '-10': '-inset-10',
  '-11': '-inset-11',
  '-12': '-inset-12',
  '-14': '-inset-14',
  '-16': '-inset-16',
  '-20': '-inset-20',
  '-24': '-inset-24',
  '-28': '-inset-28',
  '-32': '-inset-32',
  '-36': '-inset-36',
  '-40': '-inset-40',
  '-44': '-inset-44',
  '-48': '-inset-48',
  '-52': '-inset-52',
  '-56': '-inset-56',
  '-60': '-inset-60',
  '-64': '-inset-64',
  '-72': '-inset-72',
  '-80': '-inset-80',
  '-96': '-inset-96',
  '-1/2': '-inset-1/2',
  '-1/3': '-inset-1/3',
  '-2/3': '-inset-2/3',
  '-2/4': '-inset-2/4',
  '-3/4': '-inset-3/4',
}

export const positioningInsetXMap: Record<Positioning, string> = {
  '0': 'inset-x-0',
  px: 'inset-x-px',
  '0.5': 'inset-x-0.5',
  '1': 'inset-x-1',
  '1.5': 'inset-x-1.5',
  '2': 'inset-x-2',
  '2.5': 'inset-x-2.5',
  '3': 'inset-x-3',
  '3.5': 'inset-x-3.5',
  '4': 'inset-x-4',
  '5': 'inset-x-5',
  '6': 'inset-x-6',
  '7': 'inset-x-7',
  '8': 'inset-x-8',
  '9': 'inset-x-9',
  '10': 'inset-x-10',
  '11': 'inset-x-11',
  '12': 'inset-x-12',
  '14': 'inset-x-14',
  '16': 'inset-x-16',
  '20': 'inset-x-20',
  '24': 'inset-x-24',
  '28': 'inset-x-28',
  '32': 'inset-x-32',
  '36': 'inset-x-36',
  '40': 'inset-x-40',
  '44': 'inset-x-44',
  '48': 'inset-x-48',
  '52': 'inset-x-52',
  '56': 'inset-x-56',
  '60': 'inset-x-60',
  '64': 'inset-x-64',
  '72': 'inset-x-72',
  '80': 'inset-x-80',
  '96': 'inset-x-96',
  auto: 'inset-x-auto',
  '1/2': 'inset-x-1/2',
  '1/3': 'inset-x-1/3',
  '2/3': 'inset-x-2/3',
  '2/4': 'inset-x-2/4',
  '3/4': 'inset-x-3/4',
  full: 'inset-x-full',
  '-px': '-inset-x-px',
  '-0.5': '-inset-x-0.5',
  '-1': '-inset-x-1',
  '-1.5': '-inset-x-1.5',
  '-2': '-inset-x-2',
  '-2.5': '-inset-x-2.5',
  '-3': '-inset-x-3',
  '-3.5': '-inset-x-3.5',
  '-4': '-inset-x-4',
  '-5': '-inset-x-5',
  '-6': '-inset-x-6',
  '-7': '-inset-x-7',
  '-8': '-inset-x-8',
  '-9': '-inset-x-9',
  '-10': '-inset-x-10',
  '-11': '-inset-x-11',
  '-12': '-inset-x-12',
  '-14': '-inset-x-14',
  '-16': '-inset-x-16',
  '-20': '-inset-x-20',
  '-24': '-inset-x-24',
  '-28': '-inset-x-28',
  '-32': '-inset-x-32',
  '-36': '-inset-x-36',
  '-40': '-inset-x-40',
  '-44': '-inset-x-44',
  '-48': '-inset-x-48',
  '-52': '-inset-x-52',
  '-56': '-inset-x-56',
  '-60': '-inset-x-60',
  '-64': '-inset-x-64',
  '-72': '-inset-x-72',
  '-80': '-inset-x-80',
  '-96': '-inset-x-96',
  '-1/2': '-inset-x-1/2',
  '-1/3': '-inset-x-1/3',
  '-2/3': '-inset-x-2/3',
  '-2/4': '-inset-x-2/4',
  '-3/4': '-inset-x-3/4',
}

export const positioningInsetYMap: Record<Positioning, string> = {
  '0': 'inset-y-0',
  px: 'inset-y-px',
  '0.5': 'inset-y-0.5',
  '1': 'inset-y-1',
  '1.5': 'inset-y-1.5',
  '2': 'inset-y-2',
  '2.5': 'inset-y-2.5',
  '3': 'inset-y-3',
  '3.5': 'inset-y-3.5',
  '4': 'inset-y-4',
  '5': 'inset-y-5',
  '6': 'inset-y-6',
  '7': 'inset-y-7',
  '8': 'inset-y-8',
  '9': 'inset-y-9',
  '10': 'inset-y-10',
  '11': 'inset-y-11',
  '12': 'inset-y-12',
  '14': 'inset-y-14',
  '16': 'inset-y-16',
  '20': 'inset-y-20',
  '24': 'inset-y-24',
  '28': 'inset-y-28',
  '32': 'inset-y-32',
  '36': 'inset-y-36',
  '40': 'inset-y-40',
  '44': 'inset-y-44',
  '48': 'inset-y-48',
  '52': 'inset-y-52',
  '56': 'inset-y-56',
  '60': 'inset-y-60',
  '64': 'inset-y-64',
  '72': 'inset-y-72',
  '80': 'inset-y-80',
  '96': 'inset-y-96',
  auto: 'inset-y-auto',
  '1/2': 'inset-y-1/2',
  '1/3': 'inset-y-1/3',
  '2/3': 'inset-y-2/3',
  '2/4': 'inset-y-2/4',
  '3/4': 'inset-y-3/4',
  full: 'inset-y-full',
  '-px': '-inset-y-px',
  '-0.5': '-inset-y-0.5',
  '-1': '-inset-y-1',
  '-1.5': '-inset-y-1.5',
  '-2': '-inset-y-2',
  '-2.5': '-inset-y-2.5',
  '-3': '-inset-y-3',
  '-3.5': '-inset-y-3.5',
  '-4': '-inset-y-4',
  '-5': '-inset-y-5',
  '-6': '-inset-y-6',
  '-7': '-inset-y-7',
  '-8': '-inset-y-8',
  '-9': '-inset-y-9',
  '-10': '-inset-y-10',
  '-11': '-inset-y-11',
  '-12': '-inset-y-12',
  '-14': '-inset-y-14',
  '-16': '-inset-y-16',
  '-20': '-inset-y-20',
  '-24': '-inset-y-24',
  '-28': '-inset-y-28',
  '-32': '-inset-y-32',
  '-36': '-inset-y-36',
  '-40': '-inset-y-40',
  '-44': '-inset-y-44',
  '-48': '-inset-y-48',
  '-52': '-inset-y-52',
  '-56': '-inset-y-56',
  '-60': '-inset-y-60',
  '-64': '-inset-y-64',
  '-72': '-inset-y-72',
  '-80': '-inset-y-80',
  '-96': '-inset-y-96',
  '-1/2': '-inset-y-1/2',
  '-1/3': '-inset-y-1/3',
  '-2/3': '-inset-y-2/3',
  '-2/4': '-inset-y-2/4',
  '-3/4': '-inset-y-3/4',
}
