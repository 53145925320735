import type { TupleToUnion } from 'type-fest'

const flexJustifyContentNames = [
  'normal',
  'start',
  'end',
  'center',
  'between',
  'around',
  'evenly',
  'stretch',
] as const
type ThemeFlexJustifyContent = TupleToUnion<typeof flexJustifyContentNames>

const flexJustifyItemsNames = ['start', 'end', 'center', 'stretch'] as const
type ThemeFlexJustifyItems = TupleToUnion<typeof flexJustifyItemsNames>

const flexJustifySelfNames = [
  'auto',
  'start',
  'end',
  'center',
  'stretch',
] as const
type ThemeFlexJustifySelf = TupleToUnion<typeof flexJustifySelfNames>

const flexJustifyMap: Record<ThemeFlexJustifyContent, string> = {
  normal: 'justify-normal',
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
  stretch: 'justify-stretch',
}

const isFlexJustifyContentOption = (
  value: string
): value is ThemeFlexJustifyContent =>
  flexJustifyContentNames.includes(value as ThemeFlexJustifyContent)

const getFlexJustifyContentClassName = (value: string) => {
  if (isFlexJustifyContentOption(value)) {
    return flexJustifyMap[value]
  }

  return null
}

const flexJustifyItemsMap: Record<ThemeFlexJustifyItems, string> = {
  start: 'justify-items-start',
  end: 'justify-items-end',
  center: 'justify-items-center',
  stretch: 'justify-items-stretch',
}

const isFlexJustifyItemsOption = (
  value: string
): value is ThemeFlexJustifyItems =>
  flexJustifyItemsNames.includes(value as ThemeFlexJustifyItems)

const getFlexJustifyItemsClassName = (value: string) => {
  if (isFlexJustifyItemsOption(value)) {
    return flexJustifyItemsMap[value]
  }

  return null
}

const flexJustifySelfMap: Record<ThemeFlexJustifySelf, string> = {
  auto: 'justify-self-auto',
  start: 'justify-self-start',
  end: 'justify-self-end',
  center: 'justify-self-center',
  stretch: 'justify-self-stretch',
}

const isFlexJustifySelfOption = (
  value: string
): value is ThemeFlexJustifySelf =>
  flexJustifySelfNames.includes(value as ThemeFlexJustifySelf)

const getFlexJustifySelfClassName = (value: string) => {
  if (isFlexJustifySelfOption(value)) {
    return flexJustifySelfMap[value]
  }

  return null
}

export {
  flexJustifyItemsMap,
  flexJustifyMap,
  flexJustifySelfMap,
  getFlexJustifyContentClassName,
  getFlexJustifyItemsClassName,
  getFlexJustifySelfClassName,
  isFlexJustifyContentOption,
  isFlexJustifyItemsOption,
  isFlexJustifySelfOption,
  type ThemeFlexJustifyContent,
  type ThemeFlexJustifyItems,
  type ThemeFlexJustifySelf,
}
