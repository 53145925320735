import type { TupleToUnion } from 'type-fest'

const gridAutoFlowNames = [
  'row',
  'column',
  'dense',
  'row-dense',
  'column-dense',
] as const
type ThemeGridAutoFlow = TupleToUnion<typeof gridAutoFlowNames>

const gridAutoFlowMap: Record<ThemeGridAutoFlow, string> = {
  row: 'grid-flow-row',
  column: 'grid-flow-col',
  dense: 'grid-flow-dense',
  'row-dense': 'grid-flow-row-dense',
  'column-dense': 'grid-flow-col-dense',
}

const isGridAutoFlowOption = (value: string): value is ThemeGridAutoFlow =>
  gridAutoFlowNames.includes(value as ThemeGridAutoFlow)

const getGridAutoFlowClassName = (value: string) => {
  if (isGridAutoFlowOption(value)) {
    return gridAutoFlowMap[value]
  }

  return null
}

export { getGridAutoFlowClassName, gridAutoFlowNames, type ThemeGridAutoFlow }
