import type { FlatNamespace } from 'i18next'

import type { LanguageId } from '@mntn-dev/domain-types'

import { initI18next } from './utilities/init-18next.ts'

/**
 * Returns a `t` translation function and an `i18n` object.
 *
 * This function looks and acts like a React hook,
 * but it does not use hooks internally,
 * so it technically it is not a hook.
 *
 * Hooks cannot be used in server components and
 * this function CAN.
 *
 * It is not named `useTranslation` because
 * `eslint-plugin-react-hooks` complains about
 * a function named with the prefix "use"
 * on the server side.
 *
 * @param lng LanguageID
 * @param ns Namespace
 * @returns An object with a `t` function and an `i18n` object
 */
export async function getTranslation(lng: LanguageId, ns?: FlatNamespace) {
  const i18nextInstance = await initI18next(lng, ns)

  const t = i18nextInstance.getFixedT(lng, ns)

  return {
    t,
    i18n: i18nextInstance,
  }
}
