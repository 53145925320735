import type { TupleToUnion } from 'type-fest'

import { spacingNames } from './spacing.ts'

const fractionalSizeNames = [
  '1/2',
  '1/3',
  '2/3',
  '1/4',
  '2/4',
  '3/4',
  '1/5',
  '2/5',
  '3/5',
  '4/5',
  '1/6',
  '2/6',
  '3/6',
  '4/6',
  '5/6',
] as const
type ThemeFractionalSizeOption = TupleToUnion<typeof fractionalSizeNames>

const gridFractionalSizeNames = [
  '1/12',
  '2/12',
  '3/12',
  '4/12',
  '5/12',
  '6/12',
  '7/12',
  '8/12',
  '9/12',
  '10/12',
  '11/12',
] as const
type ThemeGridFractionalSizeOption = TupleToUnion<
  typeof gridFractionalSizeNames
>

const largeSizeNames = [
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
  '4xl',
  '5xl',
  '6xl',
  '7xl',
] as const
type ThemeLargeSizeOption = TupleToUnion<typeof largeSizeNames>

const screenSizeNames = [
  'screen-sm',
  'screen-md',
  'screen-lg',
  'screen-xl',
  'screen-2xl',
] as const
type ThemeScreenSizeOption = TupleToUnion<typeof screenSizeNames>

const sizeNames = [
  ...spacingNames,
  ...fractionalSizeNames,
  ...gridFractionalSizeNames,
  'auto',
  'full',
  'min',
  'max',
  'fit',
] as const
type ThemeSizingOption = TupleToUnion<typeof sizeNames>

const isSizeOption = (value: string): value is ThemeSizingOption =>
  sizeNames.includes(value as ThemeSizingOption)

const widthSizeNames = [...sizeNames, 'screen', 'svw', 'lvw', 'dvw'] as const
type ThemeWidthOption = TupleToUnion<typeof widthSizeNames>

const isWidthOption = (value: string): value is ThemeWidthOption =>
  widthSizeNames.includes(value as ThemeWidthOption)

const minWidthSizeNames = [
  ...spacingNames,
  'full',
  'min',
  'max',
  'fit',
] as const
type ThemeMinWidthOption = TupleToUnion<typeof minWidthSizeNames>

const isMinWidthOption = (value: string): value is ThemeMinWidthOption =>
  minWidthSizeNames.includes(value as ThemeMinWidthOption)

const maxWidthSizeNames = [
  ...spacingNames,
  ...largeSizeNames,
  ...screenSizeNames,
  'none',
  'full',
  'min',
  'max',
  'fit',
  'prose',
] as const
type ThemeMaxWidthOption = TupleToUnion<typeof maxWidthSizeNames>

const isMaxWidthOption = (value: string): value is ThemeMaxWidthOption =>
  maxWidthSizeNames.includes(value as ThemeMaxWidthOption)

const heightSizeNames = [
  ...spacingNames,
  ...fractionalSizeNames,
  'full',
  'screen',
  'screen-minus-10',
  'screen-minus-32',
  'screen-minus-64',
  'svh',
  'lvh',
  'dvh',
  'min',
  'max',
  'fit',
] as const
type ThemeHeightOption = TupleToUnion<typeof heightSizeNames>

const isHeightOption = (value: string): value is ThemeHeightOption =>
  heightSizeNames.includes(value as ThemeHeightOption)

const minHeightSizeNames = [
  ...spacingNames,
  'full',
  'screen',
  'screen-minus-10',
  'screen-minus-32',
  'screen-minus-64',
  'svh',
  'lvh',
  'dvh',
  'min',
  'max',
  'fit',
] as const
type ThemeMinHeightOption = TupleToUnion<typeof minHeightSizeNames>

const isMinHeightOption = (value: string): value is ThemeMinHeightOption =>
  minHeightSizeNames.includes(value as ThemeMinHeightOption)

const maxHeightSizeNames = [
  ...spacingNames,
  'none',
  'full',
  'screen',
  'screen-minus-10',
  'screen-minus-32',
  'screen-minus-64',
  'svh',
  'lvh',
  'dvh',
  'min',
  'max',
  'fit',
] as const
type ThemeMaxHeightOption = TupleToUnion<typeof maxHeightSizeNames>

const isMaxHeightOption = (value: string): value is ThemeMaxHeightOption =>
  maxHeightSizeNames.includes(value as ThemeMaxHeightOption)

type ThemeSize = {
  size?: ThemeSizingOption
}

const sizeMap: Record<ThemeSizingOption, string> = {
  '0': 'size-0',
  px: 'size-px',
  '0.5': 'size-0.5',
  '1': 'size-1',
  '1.5': 'size-1.5',
  '2': 'size-2',
  '2.5': 'size-2.5',
  '3': 'size-3',
  '3.5': 'size-3.5',
  '4': 'size-4',
  '5': 'size-5',
  '6': 'size-6',
  '7': 'size-7',
  '8': 'size-8',
  '9': 'size-9',
  '10': 'size-10',
  '11': 'size-11',
  '12': 'size-12',
  '14': 'size-14',
  '16': 'size-16',
  '20': 'size-20',
  '24': 'size-24',
  '28': 'size-28',
  '32': 'size-32',
  '36': 'size-36',
  '40': 'size-40',
  '44': 'size-44',
  '48': 'size-48',
  '52': 'size-52',
  '56': 'size-56',
  '60': 'size-60',
  '64': 'size-64',
  '72': 'size-72',
  '80': 'size-80',
  '96': 'size-96',
  '112': 'size-112',
  '128': 'size-128',
  '144': 'size-144',
  '160': 'size-160',
  '176': 'size-176',
  '192': 'size-192',
  '1/2': 'size-1/2',
  '1/3': 'size-1/3',
  '2/3': 'size-2/3',
  '1/4': 'size-1/4',
  '2/4': 'size-2/4',
  '3/4': 'size-3/4',
  '1/5': 'size-1/5',
  '2/5': 'size-2/5',
  '3/5': 'size-3/5',
  '4/5': 'size-4/5',
  '1/6': 'size-1/6',
  '2/6': 'size-2/6',
  '3/6': 'size-3/6',
  '4/6': 'size-4/6',
  '5/6': 'size-5/6',
  '1/12': 'size-1/12',
  '2/12': 'size-2/12',
  '3/12': 'size-3/12',
  '4/12': 'size-4/12',
  '5/12': 'size-5/12',
  '6/12': 'size-6/12',
  '7/12': 'size-7/12',
  '8/12': 'size-8/12',
  '9/12': 'size-9/12',
  '10/12': 'size-10/12',
  '11/12': 'size-11/12',
  auto: 'size-auto',
  full: 'size-full',
  min: 'size-min',
  max: 'size-max',
  fit: 'size-fit',
}

export {
  fractionalSizeNames,
  gridFractionalSizeNames,
  heightSizeNames,
  isHeightOption,
  isMaxHeightOption,
  isMaxWidthOption,
  isMinHeightOption,
  isMinWidthOption,
  isSizeOption,
  isWidthOption,
  largeSizeNames,
  maxHeightSizeNames,
  maxWidthSizeNames,
  minHeightSizeNames,
  minWidthSizeNames,
  screenSizeNames,
  sizeMap,
  sizeNames,
  widthSizeNames,
  type ThemeFractionalSizeOption,
  type ThemeGridFractionalSizeOption,
  type ThemeHeightOption,
  type ThemeLargeSizeOption,
  type ThemeMaxHeightOption,
  type ThemeMaxWidthOption,
  type ThemeMinHeightOption,
  type ThemeMinWidthOption,
  type ThemeScreenSizeOption,
  type ThemeSize,
  type ThemeSizingOption,
  type ThemeWidthOption,
}
