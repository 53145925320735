import type { TupleToUnion } from 'type-fest'
import { positioningBottomMap } from './bottom.ts'
import {
  positioningInsetMap,
  positioningInsetXMap,
  positioningInsetYMap,
} from './inset.ts'
import { positioningLeftMap } from './left.ts'
import type { Positioning } from './positioning.ts'
import { positioningRightMap } from './right.ts'
import { positioningTopMap } from './top.ts'

export * from './bottom.ts'
export * from './inset.ts'
export * from './left.ts'
export * from './positioning.ts'
export * from './right.ts'
export * from './top.ts'

export const positionNames = [
  'top',
  'right',
  'bottom',
  'left',
  'inset',
  'inset-x',
  'inset-y',
] as const

export type Position = TupleToUnion<typeof positionNames>
export type ThemePositioning = Partial<Record<Position, Positioning>>

export const positionMapMap: Record<Position, Record<Positioning, string>> = {
  top: positioningTopMap,
  right: positioningRightMap,
  bottom: positioningBottomMap,
  left: positioningLeftMap,
  inset: positioningInsetMap,
  'inset-x': positioningInsetXMap,
  'inset-y': positioningInsetYMap,
}
