import type { TupleToUnion } from 'type-fest'

const gridColumnValues = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  '1-auto',
  '2-auto',
  '3-auto',
  '4-auto',
  '5-auto',
  '6-auto',
  '7-auto',
  '8-auto',
  '9-auto',
  '10-auto',
  '11-auto',
  '12-auto',
  'none',
  'subgrid',
] as const
type ThemeGridColumns = TupleToUnion<typeof gridColumnValues>

const gridColumnsMap: Record<ThemeGridColumns, string> = {
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
  7: 'grid-cols-7',
  8: 'grid-cols-8',
  9: 'grid-cols-9',
  10: 'grid-cols-10',
  11: 'grid-cols-11',
  12: 'grid-cols-12',
  '1-auto': 'grid-cols-1-auto',
  '2-auto': 'grid-cols-2-auto',
  '3-auto': 'grid-cols-3-auto',
  '4-auto': 'grid-cols-4-auto',
  '5-auto': 'grid-cols-5-auto',
  '6-auto': 'grid-cols-6-auto',
  '7-auto': 'grid-cols-7-auto',
  '8-auto': 'grid-cols-8-auto',
  '9-auto': 'grid-cols-9-auto',
  '10-auto': 'grid-cols-10-auto',
  '11-auto': 'grid-cols-11-auto',
  '12-auto': 'grid-cols-12-auto',
  none: 'grid-cols-none',
  subgrid: 'grid-cols-subgrid',
}

const isGridColumnsOption = (value: unknown): value is ThemeGridColumns =>
  gridColumnValues.includes(value as ThemeGridColumns)

const getGridColumnsClassName = (value: string | number) => {
  if (isGridColumnsOption(value)) {
    return gridColumnsMap[value]
  }

  return null
}

const gridAutoColumnsNames = ['auto', 'min', 'max', 'fr'] as const
type ThemeGridAutoColumns = TupleToUnion<typeof gridAutoColumnsNames>

const gridAutoColumnsMap: Record<ThemeGridAutoColumns, string> = {
  auto: 'auto-cols-auto',
  min: 'auto-cols-min',
  max: 'auto-cols-max',
  fr: 'auto-cols-fr',
}

const isGridAutoColumnsOption = (
  value: string
): value is ThemeGridAutoColumns =>
  gridAutoColumnsNames.includes(value as ThemeGridAutoColumns)

const getGridAutoColumnsClassName = (value: string) => {
  if (isGridAutoColumnsOption(value)) {
    return gridAutoColumnsMap[value]
  }

  return null
}

const gridColumnSpan = [
  'auto',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  'full',
] as const

type ThemeGridColumnSpan = TupleToUnion<typeof gridColumnSpan>

const gridColumnSpanMap: Record<ThemeGridColumnSpan, string> = {
  auto: 'col-auto',
  1: 'col-span-1',
  2: 'col-span-2',
  3: 'col-span-3',
  4: 'col-span-4',
  5: 'col-span-5',
  6: 'col-span-6',
  7: 'col-span-7',
  8: 'col-span-8',
  9: 'col-span-9',
  10: 'col-span-10',
  11: 'col-span-11',
  12: 'col-span-12',
  full: 'col-span-full',
}

const isGridColumnSpanOption = (
  value: unknown
): value is ThemeGridColumnSpan => {
  const result = gridColumnSpan.includes(value as ThemeGridColumnSpan)
  return result
}

const getGridColumnSpanClassName = (value: string | number) => {
  if (isGridColumnSpanOption(value)) {
    return gridColumnSpanMap[value]
  }

  return null
}

const gridColumnStart = [
  'auto',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
] as const

type ThemeGridColumnStart = TupleToUnion<typeof gridColumnStart>

const gridColumnStartMap: Record<ThemeGridColumnStart, string> = {
  auto: 'col-start-auto',
  1: 'col-start-1',
  2: 'col-start-2',
  3: 'col-start-3',
  4: 'col-start-4',
  5: 'col-start-5',
  6: 'col-start-6',
  7: 'col-start-7',
  8: 'col-start-8',
  9: 'col-start-9',
  10: 'col-start-10',
  11: 'col-start-11',
  12: 'col-start-12',
  13: 'col-start-13',
}

const isGridColumnStartOption = (
  value: unknown
): value is ThemeGridColumnStart =>
  gridColumnStart.includes(value as ThemeGridColumnStart)

const getGridColumnStartClassName = (value: string | number) => {
  if (isGridColumnStartOption(value)) {
    return gridColumnStartMap[value]
  }

  return null
}

const gridColumnEnd = [
  'auto',
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
] as const

type ThemeGridColumnEnd = TupleToUnion<typeof gridColumnEnd>

const gridColumnEndMap: Record<ThemeGridColumnEnd, string> = {
  auto: 'col-end-auto',
  1: 'col-end-1',
  2: 'col-end-2',
  3: 'col-end-3',
  4: 'col-end-4',
  5: 'col-end-5',
  6: 'col-end-6',
  7: 'col-end-7',
  8: 'col-end-8',
  9: 'col-end-9',
  10: 'col-end-10',
  11: 'col-end-11',
  12: 'col-end-12',
  13: 'col-end-13',
}

const isGridColumnEndOption = (value: unknown): value is ThemeGridColumnEnd =>
  gridColumnEnd.includes(value as ThemeGridColumnEnd)

const getGridColumnEndClassName = (value: string | number) => {
  if (isGridColumnEndOption(value)) {
    return gridColumnEndMap[value]
  }

  return null
}

export {
  getGridColumnsClassName,
  getGridAutoColumnsClassName,
  getGridColumnSpanClassName,
  getGridColumnStartClassName,
  getGridColumnEndClassName,
  gridAutoColumnsNames,
  gridColumnEnd,
  gridColumnSpan,
  gridColumnStart,
  gridColumnValues,
  type ThemeGridAutoColumns,
  type ThemeGridColumnEnd,
  type ThemeGridColumns,
  type ThemeGridColumnSpan,
  type ThemeGridColumnStart,
}
